<div *ngIf="status.isLoading || !status.preloaded">
  <div class="login-wrapper">
    <div class="login-form">
      <div class="login-logo"></div>
      <sh-loading-indicator class="text-center d-block"></sh-loading-indicator>
    </div>
  </div>
</div>
<div *ngIf="status.isLoading === false && status.preloaded === true">
  <ca-sidebar *ngIf="layoutDisplay.showBaseLayout"></ca-sidebar>
  <div>
    <div
      id="sh_content_wrapper"
      [class.hide-base]="!layoutDisplay.showBaseLayout"
      [ngClass]="{ sh_content_wrapper_expanded: toggleSidebar }"
    >
      <ca-header
        *ngIf="layoutDisplay.showBaseLayout"
        id="sh_header_wrapper"
      ></ca-header>
      <div
        id="sh_content_body"
        [class.hide-base]="!layoutDisplay.showBaseLayout"
      >
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
