import { Injectable } from '@angular/core';
import { ResultWrapper } from '@seahorse/common';
import { DataContextService } from '@seahorse/common';
import { PreferencesModel } from '@seahorse/domain';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomerPortalPreferencesService {
  private tableSource = new Subject<void>();

  constructor(private dataContext: DataContextService) {}

  tableSourceChanged$ = this.tableSource.asObservable();

  search(searchQuery: string): Observable<ResultWrapper<PreferencesModel[]>> {
    searchQuery = encodeURIComponent(searchQuery);

    return this.dataContext.get<PreferencesModel[]>(
      `customerportal/preferences?searchTerm=${searchQuery}`
    );
  }

  add(model: PreferencesModel): Observable<ResultWrapper<PreferencesModel>> {
    return this.dataContext.post<PreferencesModel>(
      `customerportal/preferences`,
      model
    );
  }

  update(model: PreferencesModel): Observable<ResultWrapper<PreferencesModel>> {
    return this.dataContext.put<PreferencesModel>(
      `customerportal/preferences/${model.id}`,
      model
    );
  }

  deletePreference(id: string): Observable<ResultWrapper<PreferencesModel>> {
    return this.dataContext.delete<PreferencesModel>(
      `customerportal/preferences/${id}`
    );
  }

  getPreferenceById(id: string): Observable<ResultWrapper<PreferencesModel>> {
    return this.dataContext.get<PreferencesModel>(
      `customerportal/preferences/${id}`
    );
  }

  getPreferenceByOrganisation(): Observable<ResultWrapper<PreferencesModel>> {
    return this.dataContext.get<PreferencesModel>(
      `customerportal/preferences/organisation`
    );
  }

  updateTable() {
    this.tableSource.next();
  }
}
