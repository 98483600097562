import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IdentityService } from '../../../core/services/identity.service';
import { ProxyServices } from '../../../core/services/proxy.service';
import { MenuItem } from '../../../layout/models/menu-item.model';
import { LayoutDisplayService } from '../../../layout/services/layout-display.service';
import { Subscription } from 'rxjs';

import { UiModule } from '@seahorse/domain';
import { UiModulesService } from '../../../custom-modules/services/ui-modules.service';
import { AddCustomUIModalComponent } from '../add-custom-ui-modal/add-custom-ui-modal.component';
import { navigation } from './sidebar.config';
import { FormattingService } from '../../../core/services/formatting.service';
import { LayoutService } from '@seahorse/layout';

@Component({
  selector: 'ca-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  navigationPrimary: MenuItem[] = [] as MenuItem[];
  navigationSecondary: MenuItem[] = [] as MenuItem[];
  uiModules = [] as UiModule[];
  sidebarOpen: boolean;
  subscriptions$ = new Subscription();

  private _layoutDisplayService = inject(LayoutDisplayService);
  private _layoutService = inject(LayoutService);

  constructor(
    private _customModuleService: UiModulesService,
    private _formatting: FormattingService,
    private _identityService: IdentityService,
    private _ngbModal: NgbModal,
    private _proxyService: ProxyServices,
    private _router: Router
  ) {}

  ngOnInit() {
    this.loadNavigation();
    this.subscriptions$.add(
      this._layoutDisplayService.navigationReload$.subscribe(() => {
        this.loadNavigation();
      })
    );

    this.subscriptions$.add(
      this._layoutService.sidebarToggle$.subscribe({
        next: (sidebarOpen) => {
          this.sidebarOpen = sidebarOpen;
        },
      })
    );
  }

  navigate(str: string) {
    const findPath = location.pathname.indexOf(str);
    if (findPath !== -1) {
      this._router
        .navigateByUrl('/not-found', { skipLocationChange: true })
        .then(() => this._router.navigate([str]));
    }
  }

  navigateToModule(module: UiModule) {
    this._customModuleService.currentUiModule$.next(module);
    this._router.navigate(['module', module.systemCode, module.entryRoute]);
  }

  loadNavigation() {
    this.uiModules = this._formatting.sortBy(
      this._proxyService.uiModules,
      'menuOrder'
    );
    this.navigationPrimary = navigation.filter(
      (x) =>
        (x.isSecondary === undefined || x.isSecondary === false) &&
        (!x.permissions || this._identityService.hasPermission(x.permissions))
    );

    this.navigationPrimary.sort((item1, item2) => {
      if (item1.order > item2.order) {
        return 1;
      } else if (item1.order < item2.order) {
        return -1;
      } else {
        return 0;
      }
    });
    this.navigationSecondary = navigation.filter(
      (x) =>
        x.isSecondary !== undefined &&
        x.isSecondary === true &&
        (!x.permissions || this._identityService.hasPermission(x.permissions))
    );

    this.navigationSecondary.sort((item1, item2) => {
      if (item1.order > item2.order) {
        return 1;
      } else if (item1.order < item2.order) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  openNewCustomUiModal() {
    this._ngbModal.open(AddCustomUIModalComponent, {
      backdrop: 'static',
    });
  }

  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }

  toggleSidebar() {
    this._layoutDisplayService.toggleSidebarOpen();
  }
}
