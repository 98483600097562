import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AccountServiceBase, RegisterAccountModel } from '@seahorse/auth';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomDatadefinitionModel } from '../../../core/models/custom-service-data.model';

import { ResultStatus } from '@seahorse/common';
import { OrganisationModel } from '../../../content-distribution/models/organisation.model';
import { DataContextV2Service } from '../../../shared/services/data-context-v2.service';
import { CreateEnvironmentModel } from '../../models/create-environment.model';

@Injectable({
  providedIn: 'root',
})
export class SeahorseAccountService extends AccountServiceBase {
  private customDataObjectSource =
    new BehaviorSubject<CustomDatadefinitionModel>(null);
  customDataObjectCreated$ = this.customDataObjectSource.asObservable();

  private _dataContextV2Service = inject(DataContextV2Service);
  private _router = inject(Router);
  private _authService = inject(MsalService);

  loginWithOffice365() {
    this._authService.loginPopup().then((loginResponse) => {
      if (loginResponse.idToken) {
        const token = loginResponse.idToken.rawIdToken;
        this.identityService.setIdentity(token);

        this._router.navigate(['/']);
      }
    });
  }

  registerWithAzure(
    model: RegisterAccountModel,
    externalUserId = '',
    issuer = ''
  ) {
    return this.dataContext
      .post<any>(
        `account/register/aad?externalUserId=${externalUserId}&issuer=${issuer}`,
        model
      )
      .pipe(
        map((response) => {
          if (response.status === ResultStatus.OK) {
            const token = response.result;
            this.identityService.setIdentity(token.access_token);
          }
          return response;
        })
      );
  }

  inviteUsers(emails: string[]) {
    return this.dataContext.post<any>(`account/inviteusers`, emails).pipe(
      map((response) => {
        if (!response.hasResult || response.status !== ResultStatus.OK) {
          return false;
        }

        return true;
      })
    );
  }

  activateAccountMessage() {
    this.accountActivatedSource.next(true);
  }

  sendCustomDataObject(model: CustomDatadefinitionModel) {
    this.customDataObjectSource.next(model);
  }

  getDemoOrganisations() {
    return this.dataContext.get<OrganisationModel[]>('organisations/seeds');
  }

  createEnvironment(model: CreateEnvironmentModel) {
    return this._dataContextV2Service.post<string>(
      'Registration/create-environment',
      model
    );
  }
}
