import { inject, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  DataContextService,
  ErrorMessage,
  ResultStatus,
} from '@seahorse/common';

import {
  ChallengeResponse,
  MfaChallengeModel,
  PasswordChallengeModel,
} from '../models/challenge.model';
import { AccessToken } from '../models/identity.model';
import {
  LoginModel,
  LoginResponse,
  ResetPasswordModel,
  UpdatePasswordModel,
} from '../models/login.model';
import {
  RegisterAccountModel,
  RegisterResponse,
} from '../models/register-account.model';
import { IdentityServiceBase } from './identity.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class AccountServiceBase {
  protected accountActivatedSource = new Subject<boolean>();
  accountActivatedCalled$ = this.accountActivatedSource.asObservable();

  protected dataContext = inject(DataContextService);
  protected identityService = inject(IdentityServiceBase);
  protected translateService = inject(TranslateService);

  login(model: LoginModel) {
    return this.dataContext.post<AccessToken>('account/login', model).pipe(
      map((response) => {
        const loginResponse: LoginResponse = {};

        if (response.status !== ResultStatus.OK) {
          loginResponse.isUnauthorized = true;
          return loginResponse;
        }

        const token = response.result;
        this.identityService.setIdentity(token.access_token);

        const isMfaEnabled = !!response.result?.has_mfa;
        const isMfaRequired = !!response.result?.required_mfa;

        this.identityService.isMfaEnabled = isMfaEnabled;
        loginResponse.isMfaEnabled = isMfaEnabled;
        loginResponse.isMfaRequired = isMfaRequired;
        loginResponse.isSuccess = true;

        return loginResponse;
      })
    );
  }

  verifyMfa(code: string) {
    return this.dataContext.get<boolean>(`account/mfa/verify/${code}`).pipe(
      map((response) => {
        const loginResponse: LoginResponse = {};
        loginResponse.isSuccess = response.result;
        return loginResponse;
      })
    );
  }

  passwordChallenge(challenge: PasswordChallengeModel) {
    return this.dataContext.post<ChallengeResponse>(
      'account/challenge/password',
      challenge
    );
  }

  mfaChallenge(challenge: MfaChallengeModel) {
    return this.dataContext.post<ChallengeResponse>(
      'account/challenge/mfa',
      challenge
    );
  }

  resetPassword(model: ResetPasswordModel) {
    return this.dataContext.post<any>('account/reset-password', model).pipe(
      map((response) => {
        if (response.status !== ResultStatus.OK) {
          return false;
        }

        return true;
      })
    );
  }

  updatePassword(model: UpdatePasswordModel): Observable<boolean> {
    return this.dataContext
      .put<boolean>('account/update-password', model)
      .pipe(map((response) => response.status === ResultStatus.OK));
  }

  registerAccount(model: RegisterAccountModel, languageCode?: string) {
    let url = 'account/register';
    if (languageCode) {
      url = url + `?languageCode=${languageCode}`;
    }

    return this.dataContext.post<any>(url, model);
  }

  activateAccount(token: string, invitation?: string) {
    const url = `account/activate?token=${token}`;

    if (invitation) {
      url.concat(`&invitation=${invitation}`);
    }

    return this.dataContext.get<any>(url).pipe(
      map((response) => {
        if (!response.hasResult || response.status !== ResultStatus.OK) {
          return false;
        }

        return true;
      })
    );
  }

  resendActivationToken(token: string) {
    const url = `account/resendtoken?token=${token}`;

    return this.dataContext.get<any>(url);
  }

  activateAccountMessage() {
    this.accountActivatedSource.next(true);
  }

  getRegistrationErrors(messages: ErrorMessage[]) {
    const response = new RegisterResponse();

    if (messages.length > 0) {
      switch (messages[0].code) {
        case 'ERROR_USER_EXISTS':
          response.errorMessage = this.translateService.instant(
            'user.errors.usernameExists'
          );
          break;
        case 'ERROR_ORGANISATION_EXISTS':
          response.errorMessage = this.translateService.instant(
            'user.errors.organisationExists'
          );
          break;
        default:
          response.errorMessage = messages[0].message;
          break;
      }
    }

    return response;
  }
}
