<sh-language-picker class="d-flex justify-content-end m-2"></sh-language-picker>

<div class="container-fluid" *ngIf="messages.length > 0">
  <ca-system-message
    *ngFor="let message of messages"
    [message]="message"
  ></ca-system-message>
</div>

<div class="login-wrapper">
  <div class="login-form">
    <section>
      <div class="login-logo">{{ 'shared.branding.shipm8' | translate }}</div>
      <p *ngIf="currentForm === 1">
        {{ 'account.resetPassword.title' | translate }}
      </p>
      <form
        *ngIf="currentForm === 0"
        #loginForm="ngForm"
        (ngSubmit)="loginForm.submitted && loginForm.valid && login()"
      >
        <div class="form-group">
          <label class="control-label" for="Username">{{
            'account.terms.username' | translate
          }}</label>
          <input
            #username="ngModel"
            [(ngModel)]="loginModel.username"
            required
            id="Username"
            name="Username"
            type="text"
            class="form-control form-control-lg"
          />
          <span
            *ngIf="
              loginForm.submitted &&
              username.errors &&
              username.errors['required']
            "
            class="text-danger"
          >
            {{ 'account.login.error.usernameRequired' | translate }}
          </span>
        </div>
        <div class="form-group">
          <label class="control-label" for="Password">{{
            'account.terms.password' | translate
          }}</label>
          <input
            #password="ngModel"
            [(ngModel)]="loginModel.password"
            required
            id="Password"
            name="Password"
            type="password"
            class="form-control form-control-lg"
          />
          <span
            *ngIf="
              loginForm.submitted &&
              password.errors &&
              password.errors['required']
            "
            class="text-danger"
          >
            {{ 'account.login.error.usernameRequired' | translate }}
          </span>
        </div>
        <div class="d-flex justify-content-between mb-3">
          <div class="form-group my-0">
            <div class="form-check">
              <input
                [(ngModel)]="loginModel.rememberMe"
                id="RememberMe"
                name="RememberMe"
                type="checkbox"
                class="form-check-input"
              />
              <label class="form-check-label" for="RememberMe">
                {{ 'account.login.rememberMe' | translate }}
              </label>
            </div>
          </div>
          <button
            (click)="currentForm = 1"
            type="button"
            class="btn btn-link p-0 border-none"
          >
            {{ 'account.login.forgotPassword' | translate }}
          </button>
        </div>
        <div
          *ngIf="
            loginResponse &&
            !loginResponse.isSuccess &&
            loginResponse.isUnauthorized
          "
          class="form-group alert alert-danger"
        >
          {{ 'account.login.error.incorrectUsernamePassword' | translate }}
        </div>
        <div
          *ngIf="
            loginResponse &&
            !loginResponse.isSuccess &&
            !loginResponse.isUnauthorized
          "
          class="form-group alert alert-danger"
        >
          {{ 'account.login.error.general' | translate }}
        </div>
        <sh-loading-indicator
          class="text-center"
          *ngIf="isLoading"
        ></sh-loading-indicator>
        <div class="d-flex justify-content-between">
          <a href="https://shipm8.com/" class="small my-auto">{{
            'account.login.website' | translate
          }}</a>

          <div class="d-flex justify-content-between">
            <button type="submit" class="btn btn-primary btn-lg">
              {{ 'account.login.login' | translate }}
            </button>
            <button
              type="button"
              class="ml-2 btn btn-primary btn-lg float-right"
              (click)="loginWithOffice365()"
            >
              {{ 'account.login.loginUsingOffice365' | translate }}
            </button>
          </div>
        </div>
      </form>
      <form
        *ngIf="currentForm === 1"
        #resetPasswordForm="ngForm"
        (ngSubmit)="resetPassword(resetPasswordForm)"
      >
        <div class="form-group">
          <label class="control-label" for="emailAddress">{{
            'account.terms.emailAddress' | translate
          }}</label>
          <input
            #emailAddress="ngModel"
            [(ngModel)]="resetPasswordForm.emailAddress"
            required
            id="emailAddress"
            name="emailAddress"
            type="email"
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            class="form-control"
          />
        </div>
        <div class="d-flex">
          <button
            (click)="currentForm = 0"
            type="button"
            class="btn btn-link p-0 ml-auto"
          >
            {{ 'account.login.backToLogin' | translate }}
          </button>
        </div>
        <button
          [disabled]="emailAddress.invalid || isResetPassword"
          type="submit"
          class="btn btn-primary"
        >
          <span *ngIf="isResetPassword">
            <i class="fa-regular fa-spinner fa-spin fa-fw"></i>
          </span>
          {{ 'account.resetPassword.title' | translate }}
        </button>
      </form>

      <form
        *ngIf="currentForm === 2 && !redirecting"
        #mfaForm="ngForm"
        (ngSubmit)="sendVerificationCode(verificationCodeControl.value)"
      >
        <div class="form-group">
          <label class="control-label text-center mb-3" for="VerificationCode">
            {{ 'user.mfa.verificationCode.login' | translate }}
          </label>
          <input
            #verificationCodeControl="ngModel"
            [(ngModel)]="verificationCode"
            required
            id="verificationCode"
            name="VerificationCode"
            class="form-control form-control-lg"
          />
          <span
            *ngIf="
              mfaForm.submitted &&
              verificationCodeControl.errors &&
              verificationCodeControl.errors['required']
            "
            class="text-danger"
          >
            {{ 'user.mfa.verificationCodeRequired' | translate }}
          </span>
        </div>
        <sh-loading-indicator
          class="text-center"
          *ngIf="isLoading"
        ></sh-loading-indicator>
        <div class="d-flex justify-content-between">
          <button type="submit" class="btn btn-primary btn-lg">
            {{ 'shared.terms.verify' | translate }}
          </button>
          <button
            type="submit"
            class="btn btn-link"
            (click)="currentForm = 0; redirecting = false"
          >
            {{ 'shared.terms.backToLogin' | translate }}
          </button>
        </div>
      </form>

      <div *ngIf="currentForm === 3 && !redirecting">
        <sh-mfa-setup #mfaSetup [setupCode]="mfaSetupCode"></sh-mfa-setup>
        <div class="d-flex justify-content-between mt-2">
          <button
            type="button"
            class="btn btn-primary btn-lg"
            (click)="completeMfa()"
          >
            {{ 'shared.terms.confirm' | translate }}
          </button>
          <button
            type="submit"
            class="btn btn-link"
            (click)="currentForm = 0; redirecting = false"
          >
            {{ 'shared.terms.backToLogin' | translate }}
          </button>
        </div>
      </div>

      <div class="ml-auto" *ngIf="redirecting">
        <div>{{ 'shared.terms.redirecting' | translate }}...</div>
      </div>
    </section>
  </div>
</div>
