import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { SeahorseTemplateDirective } from '@seahorse/common';
import { BaseControl } from '../base.control';
import { MultiSelectOption } from '../multi-select/multi-select.model';
import { FilteringEventArgs } from '@syncfusion/ej2-angular-dropdowns';

@Component({
  selector: 'sh-multi-select',
  templateUrl: './multi-select.control.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectControl),
      multi: true,
    },
    {
      provide: BaseControl,
      useExisting: forwardRef(() => MultiSelectControl),
    },
  ],
  styles: [
    `
      .e-list-icon {
        margin-right: 0.5rem;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiSelectControl extends BaseControl<
  string[] | number[] | null
> {
  @ContentChild(SeahorseTemplateDirective) template?: SeahorseTemplateDirective;
  @Input() mustMatchOptions = true;
  @Input() options: MultiSelectOption[] = [];
  @Input() maxItems?: number;
  @Input() type: 'text' | 'number' = 'text';
  @Input() customItemTemplate?: TemplateRef<any>;

  @Output() itemSelected = new EventEmitter<any>();
  @Output() itemDeselected = new EventEmitter<any>();
  @Output() filterChanged = new EventEmitter<string|null>();

  fieldMapping = { text: 'label', value: 'value', iconCss: 'icon' };

  get templateRef() {
    return this.template?.templateRef;
  }

  override writeValue(value: string[] | number[] | null) {
    if (this.type === 'number') {
      if (Array.isArray(value) && value.length > 0) {
        this.value = value.map((val) => String(val));
        this.control?.setValue(this.value);
      }
    }
  }

  override emitOnChange(value: string[] | number[] | null) {
    let transformedValue: string[] | number[] | null = value;

    if (this.type === 'number' && Array.isArray(value)) {
      transformedValue = value.map((val) => Number(val));
    }

    if (this.onChangeCb) {
      this.onChangeCb(transformedValue);
    }

    if (this.onTouchedCb) {
      this.onTouchedCb();
    }
  }

  onItemSelect(event: any): void {
    const value = event.itemData?.value;
    if (value !== undefined) {
      this.itemSelected.emit(value);
    }
  }

  onItemDeselect(event: any): void {
    const value = event.itemData?.value;
    if (value !== undefined) {
      this.itemDeselected.emit(value);
    }
  }

  onFilterChange(event: FilteringEventArgs) {
    this.filterChanged.emit(event.text || null);
  }
}
