import { Component, Input } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@seahorse/common';

@Component({
  selector: 'sh-copy-content-button',
  templateUrl: 'copy-content-button.component.html',
  standalone: true,
  imports: [
    TranslateModule
  ]
})
export class CopyContentButtonComponent {
  @Input() content = '';

  constructor(
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) {}

  copy() {
    if (!this.content || this.content.toString().length === 0) {
      return;
    }

    // Let's pull this into something more generic later
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.content;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.notificationService.showInfo('shared.terms.copied');
  }
}
