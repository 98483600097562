import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { InvoicingFlowsModule } from '../../invoicing-and-tariffs/modules/invoicing-flows/invoicing-flows.module';
import { InvoicePipesModule } from '../../invoicing-and-tariffs/modules/invoicing/pipes/invoice-pipes.module';
import { LayoutComponentsModule } from '../../layout/components/layout-components.module';
import { SharedModule } from '../../shared/shared.module';

import { CoreModule } from '../core.module';
import { CustomDataDefinitionComponent } from './components/custom-data-definition/custom-data-definition.component';
import { InvoicingSetupComponent } from './components/invoicing-setup/invoicing-setup.component';
import { LoginIdentityProvidersComponent } from './components/login-identity-providers/login-identity-providers.component';
import { LoginComponent } from './components/login.component';
import { RegisterAccountComponent } from './components/register-account/register-account.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { SetPermissionsComponent } from './components/set-permissions/set-permissions.component';
import { StatusProgressPipe } from '@seahorse/temp';
import { CreateEnvironmentComponent } from './components/create-environment/create-environment.component';
import { LanguagePickerComponent, MfaSetupComponent } from '@seahorse/ui';

@NgModule({
  declarations: [
    LoginComponent,
    LoginIdentityProvidersComponent,
    ResetPasswordComponent,
    RegisterAccountComponent,
    SetPermissionsComponent,
    CustomDataDefinitionComponent,
    InvoicingSetupComponent,
    CreateEnvironmentComponent,
  ],
  imports: [
    LayoutComponentsModule,
    InvoicingFlowsModule,
    CoreModule,
    CommonModule,
    FormsModule,
    NgSelectModule,
    TranslateModule,
    InvoicePipesModule,
    SharedModule,
    NgbModule,
    MfaSetupComponent,
    StatusProgressPipe,
    LanguagePickerComponent,
  ],
})
export class AccountModule {}
