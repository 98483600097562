export class RegisterAccountModel {
  companyName?: string;
  appKey?: string;
  appSecret?: string;
  productType?: string;
  sendConfirmationEmails: boolean;
  requireAccountConfirmation: boolean;
  users: RegisterUserModel[];

  constructor() {
    this.sendConfirmationEmails = false;
    this.requireAccountConfirmation = true;
    this.users = [];
  }
}

export class RegisterUserModel {
  username?: string;
  password?: string;
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  phoneNumber?: string;
}

export class RegisterResponse {
  isSuccess?: boolean;
  isUnauthorized?: boolean;
  errorMessage?: string;
}
