import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AttributeListComponent } from '@seahorse/temp';
import { CustomUIModule } from '../../custom-ui/custom-ui.module';
import { LayoutComponentsModule } from '../../layout/components/layout-components.module';
import { NauticalGeographyComponentsModule } from '../../nautical-geography/components/nautical-geography-components.module';
import { SharedModule } from '../../shared/shared.module';

import { MapPipesModule } from '../pipes/map-pipes.module';
import { GroupFormComponent } from './group-form/group-form.component';
import { GroupModalComponent } from './group-modal/group-modal.component';
import { MapAreaShipListComponent } from './map-area-ship-list/map-area-ship-list.component';
import { MapAreasEditModalComponent } from './map-areas-edit-modal/map-areas-edit-modal.component';
import { MapBoxDisplayCenterpointComponent } from './map-box-display-centerpoint/map-box-display-centerpoint.component';
import { MapBoxDisplayPolygonComponent } from './map-box-display-polygon/map-box-display-polygon.component';
import { MapBoxDisplayTrackComponent } from './map-box-display-track/map-box-display-track.component';
import { MapBoxKioskDisplayComponent } from './map-box-kiosk-display/map-box-kiosk-display.component';
import { MapBoxShipDisplayComponent } from './map-box-ship-display/map-box-ship-display.component';
import { MapBoxComponent } from './map-box/map-box.component';
import { MapLabelComponent } from './map-label/map-label.component';
import { MapMarkerComponent } from './map-marker/map-marker.component';
import { MapPopupActionsComponent } from './map-popup-actions/map-popup-actions.component';
import { MapPopupCenterpointComponent } from './map-popup-centerpoint/map-popup-centerpoint.component';
import { MapPopupLargeComponent } from './map-popup-large/map-popup-large.component';
import { MapPopupComponent } from './map-popup/map-popup.component';
import { MapSidebarAreaActivityComponent } from './map-sidebar-area-activity/map-sidebar-area-activity.component';
import { MapSidebarConfigurationComponent } from './map-sidebar-configuration/map-sidebar-configuration.component';
import { MapSidebarFleetComponent } from './map-sidebar-fleet/map-sidebar-fleet.component';
import { MyFleetFormComponent } from './my-fleet-form/my-fleet-form.component';
import { MyFleetModalComponent } from './my-fleet-modal/my-fleet-modal.component';
import { NauticalMapHeaderComponent } from './nautical-map-header/nautical-map-header.component';
import { NauticalMapComponent } from './nautical-map/nautical-map.component';
import { MapRouteCalculatorComponent } from './route-calculator/route-calculator.component';
import { CopyContentButtonComponent } from '@seahorse/ui';

@NgModule({
  declarations: [
    MapAreasEditModalComponent,
    MapBoxComponent,
    MapBoxShipDisplayComponent,
    MapBoxKioskDisplayComponent,
    MapBoxDisplayCenterpointComponent,
    NauticalMapComponent,
    NauticalMapHeaderComponent,
    MapLabelComponent,
    MapPopupComponent,
    MapPopupLargeComponent,
    MapMarkerComponent,
    MapPopupCenterpointComponent,
    MapBoxDisplayPolygonComponent,
    MapBoxDisplayTrackComponent,
    MapRouteCalculatorComponent,
    MapSidebarAreaActivityComponent,
    MapSidebarConfigurationComponent,
    MapSidebarFleetComponent,

    GroupModalComponent,
    GroupFormComponent,
    MyFleetModalComponent,
    MyFleetFormComponent,
    MapPopupActionsComponent,
    MapAreaShipListComponent,
  ],
  exports: [
    MapBoxComponent,
    MapBoxKioskDisplayComponent,
    MapBoxShipDisplayComponent,
    NauticalMapComponent,
    NauticalMapHeaderComponent,
    MapBoxDisplayCenterpointComponent,
    MapBoxDisplayPolygonComponent,
    MapBoxDisplayTrackComponent,
    MapPopupCenterpointComponent,
    MapPopupLargeComponent,
    MapRouteCalculatorComponent,
    MapSidebarAreaActivityComponent,
    MapSidebarConfigurationComponent,
    MapSidebarFleetComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    LayoutComponentsModule,
    NgbModule,
    SharedModule,
    CustomUIModule,
    FormsModule,
    MapPipesModule,
    NauticalGeographyComponentsModule,
    AttributeListComponent,    
    CopyContentButtonComponent
  ],
})
export class MapComponentsModule {}
