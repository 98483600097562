import { CommonModule } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { SeahorseFormBuilder, SeahorseFormsModule } from '@seahorse/forms';
import { MfaSetupCode } from '@seahorse/domain';
import { CopyContentButtonComponent } from '../copy-content-button/copy-content-button.component';

@Component({
  selector: 'sh-mfa-setup',
  templateUrl: './mfa-setup.component.html',
  styleUrls: ['./mfa-setup.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    SeahorseFormsModule,
    CopyContentButtonComponent,
  ],
})
export class MfaSetupComponent {
  codeControl = inject(SeahorseFormBuilder).control<string | null>(
    null,
    Validators.required
  );

  private _setupCode!: MfaSetupCode;

  @Input()
  set setupCode(val: MfaSetupCode) {
    this._setupCode = val;
    this.safeSrc = this._sanitizer.bypassSecurityTrustResourceUrl(val.qrCode);
  }

  get setupCode() {
    return this._setupCode;
  }

  safeSrc?: SafeResourceUrl;

  showManualEntryCode = false;

  private _sanitizer = inject(DomSanitizer);
}
