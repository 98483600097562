export interface LoginModel {
  username?: string;
  password?: string;
  rememberMe: boolean;
  grantType: string;
}

export interface LoginResponse {
  isSuccess?: boolean;
  isUnauthorized?: boolean;
  isMfaEnabled?: boolean;
  isMfaRequired?: boolean;
}

export class PasswordLoginModel implements LoginModel {
  readonly grantType = 'password';
  username?: string;
  password?: string;
  rememberMe: boolean;

  constructor() {
    this.rememberMe = false;
  }
}

export class ResetPasswordModel {
  emailAddress: string | null;

  constructor(emailAddress: string) {
    this.emailAddress = emailAddress || null;
  }
}

export class UpdatePasswordModel {
  encryptedString: string | null;
  newPassword: string | null;

  constructor(encryptedString: string, newPassword: string) {
    this.encryptedString = encryptedString || null;
    this.newPassword = newPassword ||  null;
  }
}
