import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { TranslateService } from '@ngx-translate/core';
import {
  RegisterAccountModel,
  RegisterResponse,
  RegisterUserModel,
} from '@seahorse/auth';
import {
  ErrorMessage,
  NotificationService,
  ResultStatus,
} from '@seahorse/common';
import { SeahorseAccountService } from '../../services/user-account.service';

@Component({
  selector: 'ca-register-account',
  templateUrl: './register-account.component.html',
})
export class RegisterAccountComponent implements OnInit {
  isLoading = false;
  isSubmitted = false;
  registerModel: RegisterAccountModel;
  registerResponse: RegisterResponse;
  user: RegisterUserModel;
  termsAccept: boolean;
  passwordConfirmation: string;
  organisationId: string;

  aadUser = null;
  aadIdentifier = null;
  aadIssuer = null;

  constructor(
    private authService: MsalService,
    private accountService: SeahorseAccountService,
    public router: Router,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private translateService: TranslateService,
  ) {
    this.registerModel = new RegisterAccountModel();
    this.user = new RegisterUserModel();
  }

  ngOnInit() {
    this.route.queryParamMap.subscribe((params) => {
      this.organisationId = params.get('organisationId');
      this.user.emailAddress = params.get('email');

      this.aadIdentifier = params.get('aadIdentifier');

      if (this.aadIdentifier) {
        this.aadUser = this.authService.getAccount();

        this.aadIssuer =
          this.aadUser.idToken && this.aadUser.idToken.idp
            ? this.aadUser.idToken.idp
            : 'Azure Active Directory';

        this.user.lastName = this.aadUser.idToken.family_name;
        this.user.firstName = this.aadUser.idToken.given_name;
        this.user.emailAddress = this.aadUser.idToken.emails[0];
        this.user.username = this.aadUser.idToken.name;

        this.user.password = this.generatePassword();
        this.passwordConfirmation = this.user.password;
      }
    });
  }

  registerAccount() {
    if (!this.termsAccept) {
      return;
    }

    this.isLoading = true;

    this.registerModel.users = [];
    this.registerModel.users.push(this.user);

    const languageCode = this.translateService.getDefaultLang();

    if (this.aadIdentifier) {
      this.user.username = this.user.emailAddress;

      this.registerAccountWithAAD();
    } else {
      this.registerAccountLocal(languageCode);
    }
  }

  registerAccountWithAAD() {
    this.accountService
      .registerWithAzure(this.registerModel, this.aadIdentifier, this.aadIssuer)
      .subscribe(
        (response) => {
          if (response.hasResult && response.status === ResultStatus.OK) {
            this.router.navigate(['/']);
          } else {
            this.registerResponse = this.accountService.getRegistrationErrors(response.messages);

            this.isLoading = false;
            this.isSubmitted = false;
          }
        },
        (error) => {
          this.registerResponse = this.accountService.getRegistrationErrors(error);

          this.isLoading = false;
          this.isSubmitted = false;
        }
      );
  }

  registerAccountLocal(languageCode?: string) {
    this.user.username = this.user.emailAddress;

    this.accountService
      .registerAccount(this.registerModel, languageCode)
      .subscribe(
        (response) => {
          if (response.hasResult && response.status === ResultStatus.OK) {
            this.notificationService.showSuccess(
              this.translateService.instant('shared.terms.success')
            );

            this.router.navigateByUrl('/account/register/thank-you');
          } else {
            this.registerResponse = this.accountService.getRegistrationErrors(response.messages);

            this.isLoading = false;
            this.isSubmitted = false;
          }
        },
        (error) => {
          this.registerResponse = this.accountService.getRegistrationErrors(error);

          this.isLoading = false;
          this.isSubmitted = false;
        }
      );
  }

  generatePassword() {
    return (
      Math.random().toString(36).slice(2) +
      Math.random().toString(36).toUpperCase().slice(2)
    );
  }
}
