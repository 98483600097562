import { Component, OnDestroy, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import {
  LoginModel,
  PasswordLoginModel,
  ResetPasswordModel,
} from '@seahorse/auth';
import { IdentityService } from '../../../core/services/identity.service';
import { NotificationService } from '@seahorse/common';
import { SystemMessageModel } from '../../../layout/components/system-message/system-message.component';
import { SystemMessageType } from '../../../layout/models/system-message-type.enum';
import { ServiceMessageDataService } from '../../../preference/services/service-message-data.service';
import { CaEnvService } from '../../../shared/services/env.service';
import { AuthenticationParameters } from 'msal';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SeahorseAccountService } from '../services/user-account.service';
import { MfaService } from '@seahorse/ui';
import { MfaSetupCode } from '@seahorse/domain';
import { MfaSetupComponent } from '@seahorse/ui';
import { LoginResponse } from '@seahorse/auth';
import { SIDEBAR_LOCAL_STORAGE } from '@seahorse/layout';

@Component({
  selector: 'ca-account-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnDestroy {
  @ViewChild('mfaSetup', { static: false })
  mfaSetupComponent?: MfaSetupComponent;

  isLoading = false;
  loginModel: LoginModel;
  loginResponse: LoginResponse;
  currentForm: FormType = 0;
  isResetPassword = false;
  isLoggedIn: boolean;
  messages = [] as SystemMessageModel[];
  verificationCode?: string;
  mfaSetupCode?: MfaSetupCode;
  redirecting = false;

  private _subscriptions = new Subscription();
  private destroy$ = new Subject();

  constructor(
    private _accountService: SeahorseAccountService,
    private _notificationService: NotificationService,
    private _authService: MsalService,
    private _identityService: IdentityService,
    private _envService: CaEnvService,
    private _serviceMessageService: ServiceMessageDataService,
    private _mfaService: MfaService,
    public router: Router
  ) {
    const storedUrl = localStorage.getItem('mfaSetupInProgress');
    if (storedUrl) {
      this.router.navigateByUrl(storedUrl);
    }

    this.loginModel = new PasswordLoginModel();

    this._accountService.accountActivatedCalled$
      .pipe(takeUntil(this.destroy$))
      .subscribe((showNotification: boolean) => {
        if (showNotification) {
          this._notificationService.showSuccess(
            'Account activated!',
            'shared.terms.success'
          );
        }
      });

    this.getSystemMessageReleaseWarnings();
    this.getSystemMessageOutageWarnings();
  }

  login() {
    this.isLoading = true;
    this._subscriptions.add(
      this._accountService.login(this.loginModel).subscribe(
        (response) => {
          if (response.isSuccess) {
            localStorage.removeItem(SIDEBAR_LOCAL_STORAGE.isOpen);

            if (!response.isMfaEnabled && response.isMfaRequired) {
              this._mfaService.initMfa().subscribe((res) => {
                this.mfaSetupCode = res.result;
                this.currentForm = FormType.SetupMFA;
              });
            } else if (response.isMfaEnabled) {
              this.currentForm = FormType.MFA;
            } else {
              this.router.navigate(['/']);
            }
          }
        },
        (error) => {
          this.loginResponse = {} as LoginResponse;

          if (error.status === 401) {
            this.loginResponse.isUnauthorized = true;
          }
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        }
      )
    );
  }

  loginWithOffice365() {
    this._authService
      .loginPopup()
      .then((result) => {
        const accessTokenRequest: AuthenticationParameters = {
          scopes: [this._envService.scopeUri],
          account: result.account,
          authority: this._envService.authority,
        };

        this._authService
          .acquireTokenSilent(accessTokenRequest)
          .then((accessTokenResponse) => {
            const accessToken = accessTokenResponse.accessToken;

            this._identityService.setIdentity(accessToken);

            this.router.navigate(['/']);
          });
      })
      .catch((err) => {
        console.log('Login failed : ', err);
      });
  }

  resetPassword(ngForm: NgForm) {
    const resetPasswordModel = new ResetPasswordModel(ngForm['emailAddress']);
    this.isResetPassword = true;

    this._subscriptions.add(
      this._accountService
        .resetPassword(resetPasswordModel)
        .subscribe((response) => {
          if (response) {
            this.isResetPassword = false;

            this._notificationService.showSuccess(
              'account.resetPassword.emailSent',
              'shared.terms.success'
            );
          }
        })
    );
  }

  getSystemMessageReleaseWarnings() {
    this._serviceMessageService
      .getLatest('maintenance')
      .subscribe((response) => {
        if (response.result) {
          response.result.forEach((element) => {
            const message = new SystemMessageModel();
            message.id = element.id.toString();
            message.messageType = SystemMessageType.Warning;
            message.message = element.notes;

            this.messages.push(message);
          });
        }
      });
  }

  getSystemMessageOutageWarnings() {
    this._serviceMessageService.getLatest('outage').subscribe((response) => {
      if (response.result) {
        response.result.forEach((element) => {
          const message = new SystemMessageModel();
          message.id = element.id.toString();
          message.messageType = SystemMessageType.Error;
          message.message = element.notes;

          this.messages.push(message);
        });
      }
    });
  }

  sendVerificationCode(code: string) {
    this.isLoading = true;
    this._accountService.verifyMfa(code).subscribe((response) => {
      if (response.isSuccess) {
        this.isLoading = false;
        this.router.navigate(['/']);
        this.redirecting = true;
      } else {
        this.isLoading = false;
        this._notificationService.showError('user.mfa.invalidVerificationCode');
      }
    });
  }

  completeMfa() {
    if (!this.mfaSetupComponent) {
      return;
    }

    const code = this.mfaSetupComponent.codeControl.getRawValue();

    if (!code.length) {
      this._notificationService.showError('user.mfa.verificationCodeRequired');

      return;
    }

    this._mfaService.completeMfa(code, true).subscribe((res) => {
      if (res.result) {
        this._identityService.isMfaEnabled = true;
        this._notificationService.showSuccess(
          'user.mfa.enabled',
          'shared.terms.success'
        );
        this.router.navigate(['/']);
        this.redirecting = true;
      } else {
        this._notificationService.showError(
          'user.mfa.invalidVerificationCode',
          'shared.terms.error'
        );
      }
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }
}

export enum FormType {
  Login,
  ForgotPassword,
  MFA,
  SetupMFA,
}
