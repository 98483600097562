<section class="page-header d-flex flex-fill px-1">
  <div class="flex-grow-1 d-flex justify-content-start align-items-center">
    <button
      routerLink="/order-registrations/overview"
      type="button"
      class="btn btn-outline-secondary mx-1"
    >
      <i class="fa-solid fa-long-arrow-alt-left fa-fw"></i>
      <span class="d-none d-sm-inline-flex">{{
        'customModules.orderRegistrations.overview.title' | translate
      }}</span>
    </button>
    <button
      (click)="onEvent.emit({ action: 'openPortCallFile' })"
      *ngIf="portCallFileId"
      type="button"
      class="btn btn-outline-secondary mx-1"
    >
      {{ 'customModules.portCallFile.title' | translate }}
    </button>
  </div>
  <div class="d-flex justify-content-end align-items-center">
    <ca-content-distribution-export-button
      [contentModule]="'customcontent'"
      [systemCode]="orderRegistrationSystemCode"
      [contentType]="'st-orderRegistration'"
      [multiple]="false"
      [recipients]="recipients"
      [objectIds]="[orderId]"
      (buttonClicked)="onEvent.emit({ action: 'export', data: $event })"
      (itemExported)="onEvent.emit({ action: 'exported', data: $event })"
      (itemStored)="onEvent.emit({ action: 'stored', data: $event })"
      [objectType]="mappingKey"
      [exportData]="dataExportConfig"
    >
    </ca-content-distribution-export-button>
    <div class="dropdown ml-2">
      <button
        id="headerHamburgerButton"
        type="button"
        data-toggle="dropdown"
        class="btn btn-outline-secondary"
      >
        <i class="fa-regular fa-ellipsis-v fa-fw"></i>
      </button>
      <div class="dropdown-menu" aria-labelledby="headerHamburgerButton">
        <button
          *ngIf="hasDate"
          [disabled]="loading"
          (click)="onEvent.emit({ action: 'createCalendarAppointment' })"
          type="button"
          class="btn dropdown-item"
        >
          <i class="fa-solid fa-calendar fa-fw"></i>
          {{
            'customModules.orderRegistrations.details.createCalendarAppointment'
              | translate
          }}
        </button>
        <!--
        <button *ngIf="hasCompany" [disabled]="loading" (click)="onEvent.emit({ action: 'sendToCompany' })" type="button" class="btn dropdown-item">
          <i class="fa-regular fa-paper-plane fa-fw"></i>
          {{ 'customModules.orderRegistrations.details.sendToCompany' | translate }}
        </button>
        -->
        <button
          [disabled]="loading"
          (click)="onEvent.emit({ action: 'delete' })"
          type="button"
          class="btn dropdown-item"
        >
          <i class="fa-solid fa-trash fa-fw"></i>
          {{
            'customModules.orderRegistrations.orderRegistrationDelete'
              | translate
          }}
        </button>
      </div>
    </div>
  </div>
</section>
