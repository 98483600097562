import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { PreloaderService } from '../../../core/services/preloader.service';
import { LayoutDisplayService } from '../../../layout/services/layout-display.service';
import { Subscription } from 'rxjs';
import { LayoutService } from '@seahorse/layout';

@Component({
  selector: 'ca-default-theme',
  templateUrl: './default-theme.component.html',
  styleUrls: ['./default-theme.component.scss'],
})
export class DefaultThemeComponent implements OnInit, OnDestroy {
  toggleSidebar: boolean;

  status = {
    isLoading: true,
    preloaded: false,
  };

  subscriptions$ = new Subscription();
  private _layoutService = inject(LayoutService);

  constructor(
    public layoutDisplay: LayoutDisplayService,
    private preloader: PreloaderService
  ) {}

  ngOnInit() {
    this.preloader.preload().subscribe((response) => {
      this.status.isLoading = false;
      this.status.preloaded = true;
    });

    this.subscriptions$.add(
      this._layoutService.sidebarToggle$.subscribe({
        next: (sidebarOpen) => {
          this.toggleSidebar = sidebarOpen;
        },
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }
}
