import { Injectable } from '@angular/core';
import * as _ from 'underscore';

import {
  CompanyDataService,
  CompanyExternalKeyModel,
  CompanyModel,
  NauticalVisitEventModel,
  UserModel,
} from '@seahorse/domain';
import { CompanyExternalKeyDataService } from '../../../company/services/company-external-key-data.service';
import { NauticalPortWaypointDataService } from '../../../nautical-visit/services/nautical-port-waypoint.data.service';
import { UserDataService } from '../../../user/services/user-data.service';
import { CustomTeamModel } from '../../general/models/custom-team.model';
import { CustomTeamDataService } from '../../general/services/custom-team-data.service';
import { KPActivityModel } from '../models/kp-activity.model';

@Injectable()
export class KPActivityLogicService {
  static subTypes = {
    communicatie: [
      { code: 'aanboord', name: 'Aan boord' },
      { code: 'aanboordvanboord', name: 'Aan/van boord' },
      { code: 'vanboord', name: 'Van boord' },
      { code: 'ploegwissel', name: 'Ploegwissel' },
      { code: 'diepgang', name: 'Diepgang' },
      // { code: 'commoba', name: 'v.r.v OBA' },
      { code: 'hes', name: 'v.r.v HES' },
      { code: 'fleetcleaner', name: 'v.r.v Fleetcleaner' },
      { code: 'sunny', name: 'v.r.v Sunny' },
      // tslint:disable-next-line: quotemark
      { code: 'lloyds', name: "v.r.v Lloyd's" },
      { code: 'loodsvervoer', name: 'Loodsvervoer Amsterdams Rijnknaal' },
      { code: 'crewcash', name: 'Crew cash (€110,-) incl. retour' },
      { code: 'crewcashdollar', name: 'Crew cash ($110,-) incl. retour' },
    ],
    boord: [
      {
        code: 'reducers',
        name: 'Reducers',
        display: 'text',
        displayText: 'Aantal reducers',
      },
      {
        code: 'slangen',
        name: 'Slangen',
        display: 'text',
        displayText: 'Aantal slangen',
      },
      { code: 'fenders', name: 'Fenders plaatsen of verwijderen' },
      {
        code: 'fendersEnSlangen',
        name: 'Slangen en fenders plaatsen of verwijderen',
      },
      {
        code: 'reducersvopak',
        // name: 'Reducers v.r.v Vopak',
        name: 'Reducers v.r.v Dynamic',
        display: 'text',
        displayText: 'Aantal reducers',
      },
    ],
    materiaal: [
      {
        code: 'palletsaan',
        name: 'Pallets aan boord',
        display: 'text',
        displayText: 'Aantal pallets',
      },
      {
        code: 'palletsvan',
        name: 'Pallets van boord',
        display: 'text',
        displayText: 'Aantal pallets',
      },
      { code: 'koeriersritten', name: 'Koeriersritten' },
      { code: 'pakketjes', name: 'Pakketjes (com tarief)' },
      { code: 'palletopstap', name: 'Pallets ab vanaf opstapsteiger' },
      { code: 'bunkersample', name: 'Bunker sample' },
    ],
    slepen: [
      { code: 'dekschuit', name: 'Dekschuit slepen' },
      { code: 'walverbindingrtl', name: 'Walverbinding na meren RTL' },
      { code: 'walverbindingicl', name: 'Walverbinding plts/verw. ICL' },
      { code: 'walverbindinghks', name: 'Walverbinding plts/verw. HKS' },
    ],
    verhalen: [
      { code: 'losvast', name: 'VH los/vast' },
      { code: 'los', name: 'VH alleen los' },
      { code: 'vast', name: 'VH alleen vast' },
      {
        code: 'vhobakant',
        name: 'VH v.r.v. HES langs de kant',
        display: 'checkbox',
        displayText: 'Langer dan 1,5 uur',
      },
      {
        code: 'vhobapalen',
        name: 'VH v.r.v. HES van palen/boeien naar kant of v.v.',
      },
      {
        code: 'vhobapalenweekend',
        name: 'VH v.r.v. HES van palen/boeien naar kant of v.v. in het weekend',
      },
    ],
    standby: [
      { code: 'ankerklus', name: 'Anker klus' },
      { code: 'duikers', name: 'Duikers' },
      {
        code: 'olieschermen',
        name: 'Olieschermen/Worsten slepen/Plaatsen/Verwijderen',
      },
      { code: 'bodemonderzoek', name: 'Bodemonderzoek' },
      { code: 'kraan', name: 'Kraan meren of ontmeren' },
      { code: 'kraanhuur', name: 'Kraanhuur' },
      { code: 'motorboot', name: 'Standby motorboat' },
      { code: 'vhsmalo', name: 'Verhalen Smalo' },
      {
        code: 'wachtsman',
        name: 'Wachtsman/Sjorren/Dekwerkzaamheden',
        display: 'text',
        displayText: 'Aantal mensen',
      },
    ],
    verhuur: [
      {
        code: 'fenders255',
        name: 'Fenders KP 225x100',
        display: 'text',
        displayText: 'Aantal fenders',
      },
      {
        code: 'fenders350',
        name: 'Fenders KP 350x200',
        display: 'text',
        displayText: 'Aantal fenders',
      },
      {
        code: 'fenders550',
        name: 'Fenders KP 550x250',
        display: 'text',
        displayText: 'Aantal fenders',
      },
      { code: 'dekschuit', name: 'Verhuur dekschuit' },
      {
        code: 'gangway',
        name: 'Verhuur gangway',
        display: 'text',
        displayText: 'Aantal gangways',
      },
    ],
    trossen: [
      { code: 'trossen50', name: '4 trossen of minder' },
      { code: 'trossen100', name: '5 trossen of meer' },
    ],
  };

  berths = [];

  berthsKP = [
    { code: 'KP AU', name: 'Australiehaven KP' },
    { code: 'KP HH', name: 'Hornhaven KP' },
    { code: 'KP HW', name: 'Hemweg KP' },
    { code: 'KP WH', name: 'Westhaven KP' },
    { code: 'KP VH', name: 'Vlothaven Bunge Kantine' },
    { code: 'KP ZZWP', name: 'Zanzibarhaven KP' },
    { code: 'Oranjesl', name: 'Oranjesluis' },
    { code: 'AfrhP1W2', name: 'Afrikahaven Pier1 West2' },
    { code: 'ShWelter', name: 'Sonthaven open kade Welter' },
    { code: 'ShipdLp1', name: 'Shipdock Ligplaats 1' },
    { code: 'ShipdLp2', name: 'Shipdock Ligplaats 2' },
    { code: 'ShipdLp3', name: 'Shipdock Ligplaats 3' },
    { code: 'ShipdLp4', name: 'Shipdock Ligplaats 4' },
    { code: 'ShipdDok1', name: 'Shipdock Dok 1' },
    { code: 'ShipdDok2', name: 'Shipdock Dok 2' },
    { code: 'ShipdDok3', name: 'Shipdock Dok 3' },
    { code: 'ShipdDok4', name: 'Shipdock Dok 4' },
    { code: 'ShipdDwarsw', name: 'Shipdock Dwarswal tussen dok 3 & 4' },
    { code: 'AfrhWstN', name: 'Afrikahaven Wachtsteiger Noordzijde' },
    { code: 'AfrhWstZ', name: 'Afrikahaven Wachtsteiger Zuidzijde' },
    { code: 'AmhSteenk', name: 'Amerikahaven oude steenkorrel' },
  ];

  companies: CompanyModel[] = [];
  companyFinancialKeys: CompanyExternalKeyModel[] = [];
  people: UserModel[] = [];
  teams: CustomTeamModel[] = [];
  private portId = 5;

  constructor(
    private companyDataService: CompanyDataService,
    private companyExternalKeyDataService: CompanyExternalKeyDataService,
    private portWaypointDataService: NauticalPortWaypointDataService,
    private teamDataService: CustomTeamDataService,
    private userDataService: UserDataService
  ) {
    this.loadFinancialKeys();
    this.loadCompanies();
    this.loadBerths();
    this.loadUsersAndMemberTeams();
  }

  loadBerths() {
    this.portWaypointDataService
      .getByPortId(this.portId, 0, 1000, 'dekoperenploeg')
      .subscribe((result) => {
        if (result.hasResult) {
          this.berths = _.union(this.berthsKP, result.result);
        }
      });
  }

  loadCompanies() {
    this.companyDataService.getAll(0, 9999).subscribe((result) => {
      if (result.hasResult) {
        result.result = result.result.filter((company) =>
          company.companyFinancials.some(
            (f) =>
              (new Date(f.startsOn) < new Date() && !f.endsOn) ||
              (new Date(f.startsOn) < new Date() &&
                new Date(f.endsOn) > new Date()) ||
              (!f.startsOn && new Date(f.endsOn) > new Date()) ||
              (!f.startsOn && !f.endsOn)
          )
        );

        this.companies = _.sortBy(result.result, (c) => c.name.toLowerCase());
      }
    });
  }

  loadFinancialKeys() {
    this.companyExternalKeyDataService
      .getFinancialKeys('nautical_company_id')
      .subscribe((result) => {
        if (result.hasResult) {
          this.companyFinancialKeys = result.result;
        }
      });
  }

  loadUsersAndMemberTeams() {
    // Get all users
    this.userDataService.getByOrganisation(false).subscribe((result) => {
      if (result.hasResult) {
        this.people = result.result;
      }
    });

    // get teams
    this.teamDataService.get().subscribe((teamGetResponse) => {
      if (teamGetResponse.hasResult) {
        this.teams = teamGetResponse.result;
      }
    });
  }

  getCompanyByAgentId(agentId: number) {
    if (agentId) {
      const companyIds = _.map(
        _.filter(
          this.companyFinancialKeys,
          (key) => key.sourceValue === agentId.toString()
        ),
        'companyId'
      );
      const company = _.find(
        this.companies,
        (c) => c.financialCount > 0 && companyIds.indexOf(c.id) > -1
      );
      return company ? company.id : null;
    }

    return null;
  }

  getPONumberByEvents(nauticalVisitEvent: NauticalVisitEventModel[]) {
    if (nauticalVisitEvent && nauticalVisitEvent.length > 0) {
      const visitEvents = _.filter(
        nauticalVisitEvent,
        (pve) =>
          (pve.activityType === 'BOATMEN_ON_DEPARTURE' ||
            pve.activityType === 'BOATMEN_ON_ARRIVAL') &&
          pve.parameters.indexOf('KOP;NOTE:') > -1
      );
      const events = _.sortBy(visitEvents, 'createdOn');
      for (let index = events.length - 1; index >= 0; index--) {
        const pve = events[index];
        if (pve.remarks && pve.remarks.length > 0) {
          return pve.remarks;
        } else if (pve.parameters) {
          const remark = _.find(
            pve.parameters.split(';'),
            (v) => v.split(':')[0] === 'NOTE'
          );
          if (remark) {
            return remark.split(':')[1];
          }
        }
      }
    }

    return null;
  }

  removeFields(activity: KPActivityModel): KPActivityModel {
    if (!activity.activityType) {
      return activity;
    }

    switch (activity.activityType) {
      case 'boord':
        {
          activity.berth2 = null;
          activity.orderTime = null;
          activity.sailedTime = null;
          activity.cancelledOrder = false;
          activity.cancelledReason = null;
          activity.douane = null;
          activity.waitingTime = 0;
          activity.hasWaitingTime = false;
          activity.waitingTimeReason = null;
          activity.mail = null;
          activity.motorboat1 = false;
          activity.motorboat2 = false;
          activity.plastic = false;

          if (activity.subType === 'fenders') {
            activity.quantity = null;
          }
        }
        break;

      case 'communicatie':
        {
          activity.boardType = null;
          activity.orderTime = null;
          activity.sailedTime = null;
          activity.quantity = null;
          activity.cancelledOrder = false;
          activity.cancelledReason = null;
          activity.douane = null;
          activity.waitingTime = 0;
          activity.hasWaitingTime = false;
          activity.waitingTimeReason = null;
          activity.motorboat1 = false;
          activity.motorboat2 = false;
          activity.plastic = false;
        }
        break;

      case 'dos':
        {
          activity.berth2 = null;
          activity.boardType = null;
          activity.subType = null;
          activity.orderTime = null;
          activity.sailedTime = null;
          activity.quantity = null;
          activity.cancelledOrder = false;
          activity.cancelledReason = null;
          activity.douane = null;
          activity.waitingTime = 0;
          activity.hasWaitingTime = false;
          activity.waitingTimeReason = null;
          activity.mail = null;
          activity.motorboat1 = false;
          activity.motorboat2 = false;
          activity.plastic = false;
        }
        break;

      case 'materiaal':
        {
          activity.berth2 = null;
          activity.boardType = null;
          activity.orderTime = null;
          activity.sailedTime = null;
          activity.cancelledOrder = false;
          activity.cancelledReason = null;
          activity.waitingTime = 0;
          activity.hasWaitingTime = false;
          activity.waitingTimeReason = null;
          activity.mail = null;
          activity.motorboat1 = false;
          activity.motorboat2 = false;

          if (
            activity.subType === 'koeriersritten' ||
            activity.subType === 'pakketjes'
          ) {
            activity.quantity = null;
          }
        }
        break;

      case 'meren':
        {
          activity.berth2 = null;
          activity.boardType = null;
          activity.subType = null;
          activity.quantity = null;
          activity.cancelledOrder = false;
          activity.cancelledReason = null;
          activity.douane = null;
          activity.hasWaitingTime = false;
          activity.mail = null;
          activity.plastic = false;
        }
        break;

      case 'ontmeren':
        {
          activity.berth2 = null;
          activity.boardType = null;
          activity.subType = null;
          activity.sailedTime = null;
          activity.quantity = null;
          activity.douane = null;
          activity.hasWaitingTime = false;
          activity.mail = null;
          activity.plastic = false;
        }
        break;

      case 'overig':
        {
          activity.boardType = null;
          activity.subType = null;
          activity.orderTime = null;
          activity.sailedTime = null;
          activity.quantity = null;
          activity.cancelledOrder = false;
          activity.cancelledReason = null;
          activity.douane = null;
          activity.waitingTime = 0;
          activity.hasWaitingTime = false;
          activity.waitingTimeReason = null;
          activity.mail = null;
          activity.motorboat1 = false;
          activity.motorboat2 = false;
          activity.plastic = false;
        }
        break;

      case 'slepen':
        {
          activity.boardType = null;
          activity.orderTime = null;
          activity.sailedTime = null;
          activity.quantity = null;
          activity.cancelledOrder = false;
          activity.cancelledReason = null;
          activity.douane = null;
          activity.waitingTime = 0;
          activity.hasWaitingTime = false;
          activity.waitingTimeReason = null;
          activity.mail = null;
          activity.motorboat1 = false;
          activity.motorboat2 = false;
          activity.plastic = false;
        }
        break;

      case 'standby':
        {
          activity.berth2 = null;
          activity.boardType = null;
          activity.orderTime = null;
          activity.sailedTime = null;
          activity.cancelledOrder = false;
          activity.cancelledReason = null;
          activity.douane = null;
          activity.waitingTime = 0;
          activity.hasWaitingTime = false;
          activity.waitingTimeReason = null;
          activity.mail = null;
          activity.motorboat1 = false;
          activity.motorboat2 = false;
          activity.plastic = false;

          if (activity.subType !== 'wachtsman') {
            activity.quantity = null;
          }
        }
        break;

      case 'trossen':
        {
          activity.boardType = null;
          activity.orderTime = null;
          activity.sailedTime = null;
          activity.quantity = null;
          activity.cancelledOrder = false;
          activity.cancelledReason = null;
          activity.douane = null;
          activity.waitingTime = 0;
          activity.hasWaitingTime = false;
          activity.waitingTimeReason = null;
          activity.mail = null;
          activity.motorboat2 = false;
          activity.plastic = false;
        }
        break;

      case 'verhalen':
        {
          activity.boardType = null;
          activity.sailedTime = null;
          activity.quantity = null;
          activity.douane = null;
          activity.mail = null;
        }
        break;

      case 'verhuur':
        {
          activity.orderTime = null;
          activity.sailedTime = null;
          activity.cancelledOrder = false;
          activity.cancelledReason = null;
          activity.douane = null;
          activity.waitingTime = 0;
          activity.hasWaitingTime = false;
          activity.waitingTimeReason = null;
          activity.mail = null;
          activity.motorboat1 = false;
          activity.motorboat2 = false;
          activity.plastic = false;

          if (activity.subType === 'dekschuit') {
            activity.quantity = null;
          }
        }
        break;
    }

    return activity;
  }
}
