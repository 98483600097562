export enum AddressTypes {
  Email = 1,
  Phone = 2,
  Fax = 3,
  Postal = 4,
  Visiting = 5,
}

export enum CompanyContactStatuses {
  Active = 1,
  LeftCompany = 2,
  Diseased = 3,
}

export enum InvoiceMethods {
  Email = 1,
  Print = 2,
  Post = 3,
}

export enum CompanyOptInStatus {
  Invited = 1,
  Active = 2,
  Declined = 3,
  Disabled = 4,
}
