import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LanguageModel, Languages } from '@seahorse/common';

@Component({
  selector: 'sh-language-picker',
  templateUrl: './language-picker.component.html',
  standalone: true,
  imports: [CommonModule, TranslateModule, NgSelectModule, FormsModule]
})
export class LanguagePickerComponent {
  @Input() set language(value: string | undefined) {
    if (value) {
      this.currentLanguage = Languages.find((x) => x.value === value);
    }
  }

  @Output() languageChange = new EventEmitter<string>();

  private _translateService = inject(TranslateService);

  languageOptions = Languages;
  currentLanguage = Languages.find(
      (x) => x.value === this._translateService.getDefaultLang()
    );

  changeLanguage(language: LanguageModel) {
    this._translateService.setDefaultLang(language.value);
    this.languageChange.emit(language.value);
  }
}
