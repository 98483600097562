<ng-select
  [ngModel]="currentLanguage"
  (ngModelChange)="changeLanguage($event)"
  [items]="languageOptions"
  [searchable]="false"
  [clearable]="false"
  [placeholder]="'shared.terms.language' | translate"
  style="width: 200px"
>
  <ng-template ng-label-tmp let-item="item">
    <i [class]="item.flag"></i>
    {{ item.label }}
  </ng-template>
  <ng-template ng-option-tmp let-item="item">
    <i [class]="item.flag"></i>
    {{ item.label }}
  </ng-template>
</ng-select>
