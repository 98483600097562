import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LanguagePickerComponent } from '../language-picker/language-picker.component';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'sh-register-account-thank-you',
  templateUrl: './register-account-thank-you.component.html',
  standalone: true,
  imports: [TranslateModule, LanguagePickerComponent, RouterModule],
})
export class RegisterAccountThankYouComponent {}
