import { inject, Injectable } from '@angular/core';
import { UserModel } from '@seahorse/domain';
import { AUTH_ROUTES } from '../tokens/auth-routes.token';

const TOKEN_KEY = 'seahorse-token';

@Injectable({
  providedIn: 'root',
})
export class IdentityServiceBase {
  identity!: UserModel;
  permissions: string[] = [];

  isMfaEnabled = false;

  get token(): string {
    return localStorage.getItem(TOKEN_KEY) || '';
  }

  set token(value: string) {
    value
      ? localStorage.setItem(TOKEN_KEY, value)
      : localStorage.removeItem(TOKEN_KEY);
  }

  get isAuthenticated(): boolean {
    return !!this.token;
  }

  get userPermissions(): string[] {
    return this.permissions;
  }

  private loginRoute = inject(AUTH_ROUTES).login;

  setIdentity(accessToken: string) {
    if (!accessToken) {
      return;
    }

    this.token = accessToken;
  }

  clearIdentity() {
    localStorage.clear();
    window.sessionStorage.clear();
    window.location.replace(this.loginRoute);
  }

  hasPermission(permission: string) {
    if (permission.indexOf(',') > -1) {
      const permissionList = permission.split(',');
      const common = permissionList.filter((p) => this.permissions.includes(p));
      return common.length > 0;
    } else {
      return this.permissions.indexOf(permission) > -1;
    }
  }

  setPermissions(permissions: any) {
    if (!permissions) {
      return;
    }

    for (const prop in permissions) {
      if (permissions[prop].length === 0) {
        this.permissions.push(prop);
      } else {
        const mapped = permissions[prop].map((p: any) =>
          prop === p ? prop : prop + '.' + p
        );
        Array.prototype.push.apply(this.permissions, mapped);
      }
    }
  }

  hasFeature(feature: string): boolean {
    return true;
  }
}
