import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CompanyModel, InvoiceModel } from '@seahorse/domain';
import { CompanyDataService } from '@seahorse/domain';
import { ResultWrapper } from '@seahorse/common';
import { FlowDataService } from '../../../../../../invoicing-and-tariffs/modules/invoicing-flows/services/flow-data.service';
import * as _ from 'underscore';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'ca-invoice-creator-debtor-form',
  templateUrl: 'invoice-creator-debtor-form.component.html',
})
export class InvoiceCreatorDebtorFormComponent implements OnInit {
  @ViewChild('form', { read: NgForm }) form?: NgForm;

  @Input() invoice: InvoiceModel;

  flows: any[] = [];
  hideInvalidCompany: boolean;
  loadingCompanies: boolean;
  company: CompanyModel;
  isSubmitted = false;

  constructor(
    private companyDataService: CompanyDataService,
    private invoiceFlowData: FlowDataService,
    private translate: TranslateService
  ) {
    this.invoice = null;
    this.hideInvalidCompany = false;
    this.loadingCompanies = false;
    this.company = null;
  }

  ngOnInit() {
    this.loadFlows();
    this.companyIdChanged();

    // Defaults
    if (!this.invoice.id) {
      this.invoice.invoiceType = 2;
      this.invoice.status = 2;
      this.invoice.currency = 'EUR';
    }
  }

  companyIdChanged() {
    if (this.loadingCompanies === true) return;

    if (this.invoice?.companyId) {
      this._loadCompanyById();
    } else {
      this.company = null;
    }
  }

  companyChanged(selectedCompany: CompanyModel) {
    this.company = selectedCompany;
    if (this.company) {
      this.invoice.companyFinancialId = this.company.financialId;
      this.invoice.companyId = this.company.id;
    } else {
      this.invoice.companyFinancialId = null;
      this.invoice.companyId = null;
    }
  }

  _loadCompanyById() {
    this.loadingCompanies = true;
    this.companyDataService.getById(this.invoice.companyId).subscribe(
      (companyGetResponse: ResultWrapper<CompanyModel>) => {
        this.company = companyGetResponse.result;
        // change the invoice data only when invoice is not finalized
        if (!this.invoice.isFinalized) {
          if (
            this.company &&
            this.company.companyFinancials &&
            this.company.companyFinancials.length > 0
          ) {
            this.company.name = `${this.company.name} (${this.company.companyFinancials[0].debtorNumber})`;
            this.invoice.companyFinancialId =
              this.company.companyFinancials[0].id;
          } else {
            this.company = null;
            this.invoice.companyId = null;
            this.invoice.companyFinancialId = null;
          }
        } else {
          if (this.company) {
            this.company.name = this.invoice.companyName;
          }
        }

        this.loadingCompanies = false;
      },
      (e) => {
        this.loadingCompanies = false;
      }
    );
  }

  loadFlows() {
    this.invoiceFlowData.getActive().subscribe((flowResult) => {
      if (flowResult.hasResult) {
        this.flows = [
          { id: null, name: this.translate.instant('shared.terms.none') },
        ].concat(flowResult.result);

        if (this.flows.length > 1) {
          this.invoice.invoiceFlowId = this.flows[1].id;
        }
      }
    });
  }

  validate() {
    this.isSubmitted = true;
    const isFormValid =
      this.form?.valid &&
      this.invoice.invoiceDate &&
      this.invoice.companyFinancialId;

    return isFormValid;
  }
}
