import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@seahorse/common';
import {
  DataObjectAddType,
  DataObjectDeleteType,
  DataObjectEditType, FieldType, FieldVisibility, ObjectDefinitionModel
} from '@seahorse/domain';
import { EMPTY, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import * as _ from 'underscore';
import {
  CustomDatadefinitionModel,
  CustomServiceData,
  ServiceInformationField
} from '../../../../core/models/custom-service-data.model';
import { ResultStatus } from '@seahorse/common';
import { ProxyServices } from '../../../../core/services/proxy.service';
import { ObjectDefinitionsDataService } from '../../../../custom-data/services/object-definitions-data.service';
import { UiModulesDataService } from '../../../../custom-modules/services/ui-modules-data.service';
import { UiModulesService } from '../../../../custom-modules/services/ui-modules.service';
import { LayoutDisplayService } from '../../../../layout/services/layout-display.service';
import { PreferenceDataService } from '../../../../preference/services/preference-data.service';

import { transformToCamelCase } from '../../../../layout/util/string-utils';
import { SeahorseAccountService } from '../../services/user-account.service';

export enum FormStep {
  FirstStep,
  SecondStep,
}

@Component({
  selector: 'ca-custom-data-definition',
  templateUrl: './custom-data-definition.component.html',
  styleUrls: ['./custom-data-definition.component.scss'],
})
export class CustomDataDefinitionComponent implements OnInit {
  currentForm = FormStep.FirstStep;
  serviceData: CustomServiceData;
  newServiceInformationField: ServiceInformationField;
  fieldTypes = [];
  isSubmitted = false;
  isLoading = false;
  sameFieldExists = false;
  isSuccessfull = false;
  hasInvoices = false;
  FieldType = FieldType;
  newListItem = '';

  objectDefinition: ObjectDefinitionModel;
  customDataDefinition: CustomDatadefinitionModel;

  constructor(
    private layoutDisplay: LayoutDisplayService,
    private userAccountService: SeahorseAccountService,
    private objectDefinitionsDataService: ObjectDefinitionsDataService,
    private notificationService: NotificationService,
    private preferenceService: PreferenceDataService,
    private proxyService: ProxyServices,
    private router: Router,
    private translateService: TranslateService,
    private uiModulesDataService: UiModulesDataService,
    private uiModulesService: UiModulesService
  ) {
    this.serviceData = new CustomServiceData();
    this.serviceData.serviceInformationFields = [];
    this.serviceData.objectFieldDefinitions = [];
  }

  ngOnInit() {
    this.layoutDisplay.currentModuleName = this.translateService.instant(
      'account.register.permissions.title'
    );

    this.getFieldTypeDefinitions();

    this.fillInformationFields();

    this.newServiceInformationField = new ServiceInformationField(
      null,
      null,
      null,
      true,
      FieldVisibility.Public,
      false,
      true
    );

    this.preferenceService
      .getByName('invoices', 'permissions')
      .subscribe((result) => {
        this.hasInvoices = result.hasResult;
      });
  }

  fillInformationFields() {
    this.serviceData.serviceInformationFields.push(
      new ServiceInformationField(
        12,
        'people',
        'People',
        true,
        FieldVisibility.Public,
        true,
        false,
        null,
        'Which of my colleagues were involved'
      ),
      new ServiceInformationField(
        3,
        'orderedOn',
        'Ordered on',
        true,
        FieldVisibility.Public,
        true,
        false,
        null,
        'When the order came in'
      ),
      new ServiceInformationField(
        3,
        'startsOn',
        'Starts on',
        true,
        FieldVisibility.Public,
        true,
        false,
        null,
        'When you started the work'
      ),
      new ServiceInformationField(
        3,
        'endsOn',
        'Ends on',
        true,
        FieldVisibility.Public,
        true,
        false,
        null,
        'When you stopped the work'
      ),
      new ServiceInformationField(
        13,
        'ship',
        'Ship',
        true,
        FieldVisibility.Public,
        true,
        false,
        '{"mappingKey":"$nautical_ship_id","displayFields":["name"],"dependencies":[]}',
        'Which ship was involved'
      ),
      new ServiceInformationField(
        13,
        'customer',
        'Customer',
        true,
        FieldVisibility.Public,
        true,
        false,
        '{"mappingKey":"$companies_company_id","displayFields":["name"],"dependencies":[]}',
        'Which customer was involved'
      ),
      new ServiceInformationField(
        this.fieldTypes.find((x) => x.systemCode === '_integer').id,
        'numberOfItems',
        'Number of items',
        true,
        FieldVisibility.Public,
        false,
        true
      ),
      new ServiceInformationField(
        this.fieldTypes.find((x) => x.systemCode === '_singleline').id,
        'customerReference',
        'Customer reference',
        true,
        FieldVisibility.Public,
        false,
        true
      )
    );
  }

  changeStep() {
    this.isSubmitted = true;

    if (!CustomServiceData.validate(this.serviceData)) {
      return; // some data is missing
    }

    this.currentForm =
      this.currentForm === FormStep.FirstStep
        ? FormStep.SecondStep
        : FormStep.FirstStep;
  }

  addRow() {
    if (this.newServiceInformationField === null) {
      this.newServiceInformationField = new ServiceInformationField(
        null,
        null,
        null,
        true,
        FieldVisibility.Public,
        false,
        true
      );
    } else {
      this.addNewData(true);
    }
  }

  removeRow(data: ServiceInformationField) {
    this.serviceData.serviceInformationFields.splice(
      this.serviceData.serviceInformationFields.indexOf(data),
      1
    );
  }

  onChange() {
    this.addNewData(false);
  }

  addNewData(showNew) {
    if (!ServiceInformationField.validate(this.newServiceInformationField)) {
      return; // some data is missing
    }

    if (
      this.serviceData.serviceInformationFields &&
      this.serviceData.serviceInformationFields.length > 0 &&
      _.some(this.serviceData.serviceInformationFields, (a) => {
        return (
          a.name.toLowerCase() ===
          this.newServiceInformationField.name.toLowerCase()
        );
      })
    ) {
      this.sameFieldExists = true;
      return; // data with same field name already exists
    }

    this.sameFieldExists = false;

    this.newServiceInformationField.systemCode =
      this.newServiceInformationField.name
        .replace(/[^A-Za-z]+/g, '')
        .trim()
        .toLowerCase();

    this.serviceData.serviceInformationFields.push(
      this.newServiceInformationField
    );

    if (showNew === true) {
      this.newServiceInformationField = new ServiceInformationField(
        null,
        null,
        null,
        true,
        FieldVisibility.Public,
        false,
        true
      );
    } else {
      this.newServiceInformationField = null;
    }
  }

  save() {
    this.serviceData.systemCode = this.serviceData.name
      .replace(/[^A-Za-z]+/g, '')
      .trim()
      .toLowerCase();

    this.isLoading = true;

    this.setServiceDataConfiguration(this.serviceData);

    this.serviceData.serviceInformationFields.forEach((element) => {
      if (element.checked) {
        if (
          this.findFieldType(element.fieldTypeDefinitionId) ===
            FieldType.List &&
          element.listItems?.length
        ) {
          element.fieldRule = {
            fieldType: FieldType.List,
            max: 1,
            mustMatch: true,
            items: element.listItems.map((x) => {
              return { key: transformToCamelCase(x), value: x };
            }),
          };
        }
        this.serviceData.objectFieldDefinitions.push(element);
      }
    });

    this.objectDefinitionsDataService
      .add(this.serviceData)
      .pipe(
        mergeMap((result) => {
          if (result.status === ResultStatus.OK) {
            this.objectDefinition = result.result;

            this.customDataDefinition = new CustomDatadefinitionModel(
              result.result.id,
              result.result.name
            );

            this.proxyService.updateObjectDefinitions(this.objectDefinition);

            this.notificationService.showSuccess(
              'Custom data added!',
              'Successfully'
            );

            const uiModule = this.uiModulesService.createModuleFromDefinition(
              this.objectDefinition
            );
            return this.uiModulesDataService.add(uiModule);
          } else {
            this.notificationService.showError(
              result.messages[0].message,
              'Failed'
            );

            this.isLoading = false;

            return EMPTY;
          }
        }),
        mergeMap((response) => {
          if (response.hasResult) {
            this.proxyService.updateUiModules(response.result);
            this.layoutDisplay.reloadNavigation();

            return this.preferenceService.getByName(
              'custom_data',
              'permissions'
            );
          }

          return EMPTY;
        }),
        mergeMap((response) => {
          if (!this.hasInvoices) {
            if (response.hasResult) {
              return this.preferenceService.delete(response.result.id);
            }
          }

          return of(null);
        })
      )
      .subscribe((response) => {
        this.isLoading = false;

        this.notificationService.showSuccess(
          'Onboarding process completed!',
          'Successfully'
        );

        if (!this.hasInvoices) {
          this.router.navigate(['../dashboard']);
        } else {
          this.userAccountService.sendCustomDataObject(
            this.customDataDefinition
          );

          this.router.navigate(['onboarding/invoicing']);
        }
      });
  }

  private setServiceDataConfiguration(serviceData: CustomServiceData) {
    serviceData.addConfig = {
      type: DataObjectAddType.Anywhere,
      fields: serviceData.serviceInformationFields.map((x) => x.systemCode),
    };

    serviceData.editConfig = {
      type: DataObjectEditType.Inline,
    };

    serviceData.deleteConfig = {
      type: DataObjectDeleteType.Disable,
      remarksRequired: false,
    };

    serviceData.inboxDataConfig = {
      autoApproval: false,
    };

    serviceData.simpleSearchConfig = {
      fields: serviceData.serviceInformationFields
        .filter(
          (x) =>
            ![FieldType.LinkedObject, FieldType.LinkedObjects].includes(
              x.fieldType
            )
        )
        .map((x) => x.systemCode),
      rangeFields: null,
    };

    serviceData.advancedSearchConfig = {
      fields: serviceData.serviceInformationFields.map((x) => x.systemCode),
      rangeFields: null,
    };
  }

  getFieldTypeDefinitions() {
    const fieldTypes = this.proxyService.fieldTypeDefinitions.filter(
      (x) => x.fieldType !== FieldType.File && x.systemCode !== 'user'
    );

    fieldTypes.forEach((ft) => {
      switch (ft.fieldType) {
        case FieldType.Boolean:
          ft.name = 'Yes/No';
          break;
        case FieldType.DateTime:
          ft.name = 'Date and time';
          break;
        case FieldType.Decimal:
          ft.name = 'Number (with decimals)';
          break;
        case FieldType.File:
          ft.name = 'Uploaded file';
          break;
        case FieldType.Integer:
          ft.name = 'Number (round)';
          break;
        default:
          break;
      }
    });

    this.fieldTypes = fieldTypes;
  }

  findFieldType(fieldTypeDefinitionId: number) {
    return (
      this.proxyService.fieldTypeDefinitions.find(
        (x) => x.id === fieldTypeDefinitionId
      )?.fieldType || null
    );
  }

  addItemToList(data: ServiceInformationField) {
    if (this.newListItem && this.newListItem !== '') {
      const currentField = this.serviceData.serviceInformationFields.find(
        (x) => x === data
      );
      const clonedField = { ...currentField };

      clonedField.listItems = currentField.listItems?.length
        ? currentField.listItems.concat(this.newListItem)
        : [this.newListItem];

      this.serviceData.serviceInformationFields.splice(
        this.serviceData.serviceInformationFields.indexOf(currentField),
        1,
        clonedField
      );
    }

    this.newListItem = '';
  }

  removeItemFromList(data: ServiceInformationField, item: string) {
    const currentField = this.serviceData.serviceInformationFields.find(
      (x) => x === data
    );
    const clonedField = { ...currentField };

    clonedField.listItems.splice(clonedField.listItems.indexOf(item), 1);
  }
}
