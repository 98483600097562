import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'underscore';

import {
  CustomDataAdditionalDataModel,
  FieldDefinitionModel,
  FieldType,
  ObjectDefinitionModel,
  CustomDataBaseModel,
} from '@seahorse/domain';
import { ProxyServices } from '../../../core/services/proxy.service';
import { TableColumnModel } from '../../../layout/models/table.model';
import { PipeModel, KeyValuePair } from '@seahorse/common';
import { BadgeConfigurationModel, ShPdfViewerComponent } from '@seahorse/ui';
import {
  DataField,
  DataListComponent,
  LinkedObjectService,
  ModalService,
} from '@seahorse/temp';
import { mergeMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'ca-custom-content-list',
  templateUrl: './custom-content-list.component.html',
  styleUrls: ['./custom-content-list.component.scss'],
})
export class CustomContentListComponent implements OnInit {
  private _entries: CustomDataBaseModel[] = [];

  connectedDataBadges: Record<string, BadgeConfigurationModel[]> = {};
  connectedDataIconConfig: BadgeConfigurationModel[] = [];
  selectedEntry: CustomDataBaseModel = null;
  fieldType = null;
  hasFixWidth = false;

  idDiscriminator = `_${new Date().getTime()}`;

  @Input() allowSelection = true;
  @Input() columns: TableColumnModel[] = [];
  @Input() entryId: string = null;
  @Input() get entries(): CustomDataBaseModel[] {
    return this._entries;
  }
  set entries(value: CustomDataBaseModel[]) {
    this._entries = value;
    if (this.definition) {
      _.each(this.entries, (entry) => {
        this.connectedDataBadges[entry.__Id] =
          this.mapConnectedDataBadges(entry);
      });
    }
  }
  @Input() definition: ObjectDefinitionModel = null;
  @Input() footers: KeyValuePair<any, string>[];
  @Input() approvedField: string;

  @Output() onEntrySelected = new EventEmitter<CustomDataBaseModel>();
  @Output() checkChange = new EventEmitter<CustomDataBaseModel[]>();

  fields = {}; // {FieldSystemcode, FieldDefinition}

  get checkedItems() {
    return this.entries?.filter((item) => item['isChecked'] === true);
  }

  get showCheckboxes() {
    return this.checkChange.observers.length > 0;
  }

  constructor(
    private linkedObjectService: LinkedObjectService,
    private modalService: ModalService,
    private proxyServices: ProxyServices,
    private translate: TranslateService
  ) {
    this.fieldType = FieldType;
    this.footers = [];
    this.approvedField = null;
  }

  ngOnInit() {
    if (this.definition) {
      let systemFields = [];

      let json = null;
      if (this.definition.templateCode) {
        try {
          json = JSON.parse(this.definition.templateCode);
        } catch {}
      }

      if (json && json['sort'] && json['sort']['fields']) {
        systemFields = this.convertToFieldDefinition(json['sort']['fields']);
      } else {
        systemFields = this.convertToFieldDefinition([
          { code: '__CreatedOn', order: -4 },
          { code: '__CreatedBy', order: -3 },
          { code: '__LastEditedOn', order: -2 },
          { code: '__LastEditedBy', order: -1 },
        ]);
      }

      // sort by sortOrder then by name
      const allFields: FieldDefinitionModel[] = _.sortBy(
        _.union(
          systemFields,
          ObjectDefinitionModel.getAllFieldDefinitions(this.definition)
        ),
        (field: FieldDefinitionModel) => {
          return parseInt(field.sortOrder);
        }
      );

      if (!this.columns || this.columns.length === 0) {
        this.columns = _.map(allFields, (field) => {
          this.fields[field.systemCode] = field;

          // dirty fix for old data. e.g. CVV/KP data
          if (this.proxyServices.objectKeyMapping[field.systemCode]) {
            this.fields[field.systemCode].fieldType = FieldType.LinkedObject;
          }

          let pipes = null;
          if (
            this.fields[field.systemCode].fieldType ===
              FieldType.LinkedObject ||
            this.fields[field.systemCode].fieldType === FieldType.LinkedObjects
          ) {
            let displayFields = null; // default show all properties
            if (this.fields[field.systemCode].additionalData) {
              try {
                const additionalData: CustomDataAdditionalDataModel =
                  JSON.parse(this.fields[field.systemCode].additionalData);
                if (additionalData && additionalData.displayFields) {
                  displayFields = additionalData.displayFields;
                }
              } catch {}
            }
            pipes = [
              new PipeModel('keyValue', { displayFields: displayFields }),
            ]; // show all properties
          }

          return new TableColumnModel(
            field.systemCode,
            field.fieldType,
            field.name,
            pipes,
            false,
            false,
            field.sortOrder
          );
        });
      } else {
        this.fields = allFields;
        _.each(this.columns, (col: TableColumnModel) => {
          const def: any = _.find(
            this.fields,
            (field: any) => field.systemCode === col.fieldName
          );
          if (def) {
            if (
              def.fieldType === FieldType.LinkedObject ||
              def.fieldType === FieldType.LinkedObjects
            ) {
              let displayFields = null; // default show all properties
              if (def.additionalData) {
                try {
                  const additionalData: CustomDataAdditionalDataModel =
                    JSON.parse(def.additionalData);
                  if (additionalData && additionalData.displayFields) {
                    displayFields = additionalData.displayFields;
                  }
                } catch {}
              }
              col.pipes = [
                new PipeModel('keyValue', { displayFields: displayFields }),
              ];
            }
          }
        });
      }

      this.hasFixWidth = _.any(
        this.columns,
        (col: TableColumnModel) => col.width !== undefined && col.width !== null
      );

      // Set the connected data icons
      if (
        this.definition.connectedDataConfig &&
        this.definition.connectedDataConfig.connectedObjectInformation &&
        this.definition.connectedDataConfig.connectedObjectInformation.length >
          0
      ) {
        this.connectedDataIconConfig = _.map(
          this.definition.connectedDataConfig.connectedObjectInformation,
          (info) => {
            return {
              id: info.targetObjectKey,
              clickable: false,
              disabled: false,
              objectKey: info.targetObjectKey.split('_').slice(0, 2).join('_'),
              icon: 'fa fa-paperclip',
            };
          }
        );
      }

      _.each(this.entries, (entry) => {
        this.connectedDataBadges[entry.__Id] =
          this.mapConnectedDataBadges(entry);
      });
    }

    if (this.entryId) {
      this.selectedEntry = _.find(
        this.entries,
        (entry) => entry.__Id === this.entryId
      );
    }
  }

  selectEntry(entry: CustomDataBaseModel) {
    if (!this.allowSelection) {
      return;
    }
    this.selectedEntry = entry;
    this.onEntrySelected.emit(entry);
  }

  private convertToFieldDefinition(fields) {
    const definitions = [];
    _.each(fields, (f) => {
      const definition = new FieldDefinitionModel();
      definition.systemCode = f['code'];
      definition.name = this.translate.instant(`customData.model.${f['code']}`);
      definition.sortOrder = !f['order'] ? 999 : f['order'];

      switch (f['code']) {
        case '__CreatedOn':
        case '__LastEditedOn':
          definition.fieldTypeDefinitionId = 3; // datetime field
          break;

        case '__CreatedBy':
        case '__LastEditedBy':
          definition.fieldTypeDefinitionId = 12; // user field
          break;

        case '__AdditionalData':
          break;

        default:
          definition.fieldTypeDefinitionId = 1;
      }
      definitions.push(definition);
    });
    return definitions;
  }

  badgeClicked(badge: BadgeConfigurationModel, entry: CustomDataBaseModel) {
    if (
      entry['__ConnectedData'] &&
      entry['__ConnectedData'][badge.id] &&
      entry['__ConnectedData'][badge.id].length > 0
    ) {
      const modalRef = this.modalService
        .buildModal()
        .withTitle(badge.description)
        .withComponentBody<DataListComponent>({
          component: DataListComponent,
          props: {
            fields: [],
            items: [],
            isLoading: true,
          },
          hostAttributes: ['flex'],
        });

      const dataListComponentInstance = modalRef
        .components[0] as DataListComponent;

      modalRef
        .open({ isTable: true, hideActions: true, size: 'lg' })
        .subscribe();

      dataListComponentInstance.select.subscribe((data) => {
        this.openPdf(data);
      });

      this.linkedObjectService
        .getByKeys(
          null,
          badge.objectKey,
          null,
          entry['__ConnectedData'][badge.id]
        )
        .pipe(
          mergeMap((linkedObjectResult) => {
            const objectDefinition =
              this.proxyServices.getObjectDefinitionByMappingKey(
                badge.objectKey
              );

            if (
              objectDefinition &&
              linkedObjectResult &&
              linkedObjectResult.hasResult &&
              linkedObjectResult.result &&
              linkedObjectResult.result.length > 0
            ) {
              const fields = objectDefinition.objectFieldDefinitions
                .filter((f) => f.isRequired && f.visibility === 0)
                .map((f) => {
                  const output = DataField.fromFieldDefinition(f);
                  output.name = this.translate.instant(output.name);
                  return output;
                });

              dataListComponentInstance.fields = fields;
              dataListComponentInstance.items = linkedObjectResult.result;
            } else {
              dataListComponentInstance.emptyPlaceholderConfig = {
                size: 'md',
                message: 'shared.terms.noResult',
                icon: undefined,
              };
            }

            dataListComponentInstance.isLoading = false;

            return of(undefined);
          })
        )
        .subscribe();
    }
  }

  openPdf(item: any) {
    if (!item || item.fileType !== '.pdf' || !item.content) {
      return;
    }

    this.modalService
      .buildModal()
      .withTitle(item.name ?? 'PDF Viewer')
      .withComponentBody<ShPdfViewerComponent>({
        component: ShPdfViewerComponent,
        props: {
          title: item.name,
          base64Content: item.content,
        },
      })
      .open({ size: 'xl', hideActions: true })
      .subscribe((res) => {
        res.modalRef.close();
      });
  }

  checkAll(event) {
    this.entries.forEach(
      (entry) => (entry['isChecked'] = event.target.checked)
    );
    this.checkChange.emit(this.checkedItems);
  }

  mapConnectedDataBadges(entry: CustomDataBaseModel) {
    return _.map(this.connectedDataIconConfig, (config) => {
      const objectDefinition =
        this.proxyServices.getObjectDefinitionByMappingKey(config.objectKey);
      return {
        ...config,
        ...{
          badgeText:
            entry['__ConnectedData'] && entry['__ConnectedData'][config.id]
              ? entry['__ConnectedData'][config.id].length
              : config.badgeText ?? '0',
          clickable:
            entry['__ConnectedData'] && entry['__ConnectedData'][config.id]
              ? entry['__ConnectedData'][config.id].length > 0
              : false,
          disabled:
            !entry['__ConnectedData'] ||
            !entry['__ConnectedData'][config.id] ||
            entry['__ConnectedData'][config.id].length === 0,
          icon:
            objectDefinition &&
            objectDefinition['__UI'] &&
            objectDefinition['__UI']['icon']
              ? objectDefinition['__UI']['icon']
              : config.icon,
          description: objectDefinition
            ? objectDefinition.name
            : config.description,
        },
      };
    });
  }

  toggleChecked(entry: CustomDataBaseModel) {
    entry['isChecked'] = !entry['isChecked'];
    this.checkChange.emit(this.checkedItems);
  }
}
