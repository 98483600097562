<sh-language-picker
  class="d-flex justify-content-end m-2"
></sh-language-picker>

<div class="login-wrapper">
  <div class="login-form">
    <section>
      <div class="login-logo">{{ 'shared.branding.shipm8' | translate }}</div>
      <div class="d-flex flex-column">
        <h5 class="mt-4 text-center">
          <strong>{{ 'account.register.welcome.title' | translate }}</strong>
        </h5>
        <span class="mt-4 text-justify">{{
          'account.register.welcome.text' | translate
        }}</span>
      </div>

      <div class="d-flex justify-content-end">
        <a class="btn-link" href="/">{{
          'account.register.accountExists' | translate
        }}</a>
      </div>

      <form
        #registerForm="ngForm"
        [mustMatch]="['password', 'confirmPassword']"
        (ngSubmit)="
          registerForm.submitted && registerForm.valid && registerAccount()
        "
      >
        <div class="form-group" [hidden]="aadIdentifier">
          <label class="control-label required"
            ><b>{{ 'account.register.firstName' | translate }}</b></label
          >
          <div class="input-group">
            <input
              required
              class="form-control"
              name="firstName"
              placeholder="{{
                'account.register.placeholder.firstName' | translate
              }}"
              #firstName="ngModel"
              [(ngModel)]="user.firstName"
            />
            <span
              class="d-flex justify-content-center align-items-center"
              container="body"
              ngbTooltip="{{ 'account.register.firstName' | translate }}"
              tooltipClass="tooltip"
            >
              <i
                class="fa-regular fa-question-circle cursor-pointer register-icon"
                title="{{ 'account.register.firstName' | translate }}"
              ></i>
            </span>
          </div>
          <span
            *ngIf="
              isSubmitted && (firstName.value == null || firstName.value === '')
            "
            class="text-danger"
            >{{ 'account.register.error.firstNameRequired' | translate }}</span
          >
        </div>

        <div class="form-group" [hidden]="aadIdentifier">
          <label class="control-label required"
            ><b>{{ 'account.register.lastName' | translate }}</b></label
          >
          <div class="input-group">
            <input
              required
              class="form-control"
              name="lastName"
              placeholder="{{
                'account.register.placeholder.lastName' | translate
              }}"
              #lastName="ngModel"
              [(ngModel)]="user.lastName"
            />
            <span
              class="d-flex justify-content-center align-items-center"
              container="body"
              ngbTooltip="{{ 'account.register.lastName' | translate }}"
              tooltipClass="tooltip"
            >
              <i
                class="fa-regular fa-question-circle cursor-pointer register-icon"
                title="{{ 'account.register.lastName' | translate }}"
              ></i>
            </span>
          </div>
          <span
            *ngIf="
              isSubmitted && (lastName.value == null || lastName.value === '')
            "
            class="text-danger"
            >{{ 'account.register.error.lastNameRequired' | translate }}</span
          >
        </div>

        <div class="form-group" [hidden]="aadIdentifier">
          <label class="control-label required" for="password"
            ><b>{{ 'account.register.password' | translate }}</b></label
          >
          <div class="input-group">
            <input
              required
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}"
              class="form-control"
              placeholder="{{
                'account.register.placeholder.password' | translate
              }}"
              id="password"
              name="password"
              type="password"
              #password="ngModel"
              [(ngModel)]="user.password"
            />
            <span
              class="d-flex justify-content-center align-items-center"
              container="body"
              ngbTooltip="{{ 'account.register.password' | translate }}"
              tooltipClass="tooltip"
            >
              <i
                class="fa-regular fa-question-circle cursor-pointer register-icon"
                title=""
              ></i>
            </span>
          </div>
          <span
            *ngIf="isSubmitted && password.errors?.required"
            class="text-danger"
            >{{ 'account.register.error.passwordRequired' | translate }}</span
          >
          <span *ngIf="password.errors?.pattern" class="text-danger">
            {{ 'account.register.error.passwordPattern' | translate }}</span
          >
        </div>

        <div class="form-group" [hidden]="aadIdentifier">
          <label class="control-label required" for="confirmPassword"
            ><b>{{ 'account.register.confirmPassword' | translate }}:</b></label
          >
          <div class="input-group">
            <input
              required
              class="form-control"
              id="confirmPassword"
              placeholder="{{
                'account.register.placeholder.confirmpassword' | translate
              }}"
              name="confirmPassword"
              type="password"
              #confirmPassword="ngModel"
              [(ngModel)]="passwordConfirmation"
            />
            <span
              class="d-flex justify-content-center align-items-center"
              container="body"
              ngbTooltip="{{ 'account.register.confirmPassword' | translate }}"
              tooltipClass="tooltip"
            >
              <i
                class="fa-regular fa-question-circle cursor-pointer register-icon"
                title=""
              ></i>
            </span>
          </div>
          <span
            *ngIf="isSubmitted && confirmPassword.errors?.required"
            class="text-danger"
            >{{
              'account.register.error.confirmPasswordRequired' | translate
            }}</span
          >
          <span *ngIf="confirmPassword.errors?.mustMatch" class="text-danger">{{
            'account.register.error.passwordMismatch' | translate
          }}</span>
        </div>

        <div class="form-group">
          <label class="control-label required"
            ><b>{{ 'account.register.company' | translate }}</b></label
          >
          <div class="input-group">
            <input
              required
              class="form-control"
              name="company"
              placeholder="{{
                'account.register.placeholder.company' | translate
              }}"
              #company="ngModel"
              [(ngModel)]="registerModel.companyName"
            />
            <span
              class="d-flex justify-content-center align-items-center"
              container="body"
              ngbTooltip="{{ 'account.register.company' | translate }}"
              tooltipClass="tooltip"
            >
              <i
                class="fa-regular fa-question-circle cursor-pointer register-icon"
                title=""
              ></i>
            </span>
          </div>
          <span
            *ngIf="
              isSubmitted && (company.value == null || company.value === '')
            "
            class="text-danger"
            >{{ 'account.register.error.companyRequired' | translate }}</span
          >
        </div>

        <div class="form-group" [hidden]="aadIdentifier">
          <label class="control-label required"
            ><b>{{ 'account.register.email' | translate }}</b></label
          >
          <div class="input-group">
            <input
              required
              pattern="\S+@\S+\.\S+$"
              placeholder="{{
                'account.register.placeholder.email' | translate
              }}"
              class="form-control"
              name="givenName"
              #email="ngModel"
              [(ngModel)]="user.emailAddress"
            />
            <span
              class="d-flex justify-content-center align-items-center"
              container="body"
              ngbTooltip="{{ 'account.register.email' | translate }}"
              tooltipClass="tooltip"
            >
              <i
                class="fa-regular fa-question-circle cursor-pointer register-icon"
                title=""
              ></i>
            </span>
          </div>
          <span
            *ngIf="isSubmitted && (email.value == null || email.value === '')"
            class="text-danger"
            >{{ 'account.register.error.emailRequired' | translate }}</span
          >
          <span
            *ngIf="isSubmitted && email.errors?.pattern"
            class="text-danger"
          >
            {{ 'account.register.error.emailPattern' | translate }}</span
          >
        </div>

        <div class="form-group d-flex justify-content-between">
          <label class="cursor-pointer">
            <input
              class="cursor-pointer"
              id="terms"
              name="terms"
              type="checkbox"
              [(ngModel)]="termsAccept"
            />
            {{ 'account.register.iAgree' | translate }}
          </label>
          <a class="btn-link" href="https://shipm8.com">{{
            'account.register.termsAndConditions' | translate
          }}</a>
        </div>

        <div
          class="form-group alert alert-danger"
          *ngIf="isSubmitted && !termsAccept"
        >
          {{ 'account.register.error.acceptTerms' | translate }}
        </div>
        <div
          class="form-group alert alert-danger"
          *ngIf="
            registerResponse &&
            !registerResponse.isSuccess &&
            registerResponse.isUnauthorized
          "
        >
          {{ 'account.register.error.unauthorized' | translate }}
        </div>

        <div
          class="form-group alert alert-danger"
          *ngIf="
            registerResponse &&
            !registerResponse.isSuccess &&
            !registerResponse.isUnauthorized
          "
        >
          {{ registerResponse.errorMessage }}
        </div>

        <div class="row register-account">
          <button
            type="submit"
            class="btn btn-primary btn-lg"
            (click)="isSubmitted = true"
          >
            {{ 'account.register.iAmReady' | translate }}
          </button>
        </div>

        <div class="row register-account mt-2">
          <sh-loading-indicator
            class="text-center loader-sm"
            *ngIf="isLoading"
          ></sh-loading-indicator>
        </div>
      </form>
    </section>
  </div>
</div>
