import { Component, inject, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ContentDistributionRecipientModel } from '../../../content-distribution/models/content-distribution-recipient.model';
import { createExportSelectRecipientModalForm } from './export-select-recipient-modal.form';
import { ContentDistributionDataService } from '../../services/content-distribution-data.service';
import { ExportOptionItemModel } from '../../models/content-distribtion-export-option.model';
import { ExportTemplateDataService } from '../../services/export-template.service';
import {
  ExportTemplateModel,
  ExportTemplatePreviewStates,
} from '../../models/export-template.model';
import { HttpErrorResponse } from '@angular/common/http';
import { MultiSelectOption } from '@seahorse/forms';
import {
  AddressTypes,
  CompanyAddressModel,
  CustomDataSearchFieldModel,
  FileService,
} from '@seahorse/domain';
import { debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';
import { forkJoin, of, Subject, Subscription } from 'rxjs';
import { NotificationService, ResultWrapper } from '@seahorse/common';
import { NotificationConfigurationModel } from '../../models/notification.configuration.model';
import { NotificationConfigurationService } from '../../services/notification-configuration.service';
import { AddressDataService } from '../../../company/services/address-data.service';

@Component({
  selector: 'ca-export-select-recipient-modal',
  templateUrl: 'export-select-recipient-modal.component.html',
  styleUrls: ['export-select-recipient-modal.component.scss'],
})
export class ExportSelectRecipientModalComponent implements OnInit, OnDestroy {
  private _exportOption!: ExportOptionItemModel;
  private _recipients: ContentDistributionRecipientModel[] = [];
  private _messageBody: string;
  private _messageSubject: string;

  @Input() get exportOption(): ExportOptionItemModel {
    return this._exportOption;
  }
  set exportOption(input: ExportOptionItemModel) {
    this._exportOption = input;
    if (this._exportOption && this._exportOption.previewState) {
      (this.allowPreview =
        this._exportOption.previewState ===
        ExportTemplatePreviewStates.AllowPreview),
        (this.showPreview =
          this._exportOption.previewState ===
          ExportTemplatePreviewStates.AlwaysShow);
    }
  }
  @Input() objectIds?: any[];
  @Input() data?: any;
  @Input() objectType!: string;
  @Input() showHeader = true;
  @Input() get recipients(): ContentDistributionRecipientModel[] {
    return this._recipients;
  }
  set recipients(input: ContentDistributionRecipientModel[]) {
    if (input) {
      this._recipients = input;
    } else {
      this._recipients = [];
    }
  }
  @Input() searchFields: CustomDataSearchFieldModel[] = null;
  get messageBody(): string {
    return this._messageBody;
  }
  @Input() set messageBody(body: string) {
    this._messageBody = body;
    this.form.controls.notificationBody.patchValue(body);
    this.notificationBodyLoaded = true;
  }
  get messageSubject(): string {
    return this._messageSubject;
  }
  @Input() set messageSubject(subject: string) {
    this._messageSubject = subject;
    this.form.controls.subject.patchValue(subject);
  }

  additionalRecipient: string = null;
  allowPreview = false;
  form = createExportSelectRecipientModalForm();
  addressError?: HttpErrorResponse;
  loadingPreview = false;
  notificationBodyLoaded = false;
  previewError?: HttpErrorResponse;
  recipientOptions: MultiSelectOption[] = [];
  recipientSelectFields = { text: 'address', value: 'address' };
  showPreview = false;
  allIncludedFiles = [];
  includedTemplates = [];
  exportTemplate: ExportTemplateModel;
  senderAddresses: string[] = [];
  private _subscription = new Subscription();
  private _searchTerm = new Subject<string|null>();
  private _addressDataService = inject(AddressDataService);

  constructor(
    public contentDistributionData: ContentDistributionDataService,
    public modal: NgbActiveModal,
    public templateData: ExportTemplateDataService,
    public fileData: FileService,
    private notificationService: NotificationService,
    private _notificationConfigurationService: NotificationConfigurationService
  ) {}

  ngOnInit(): void {
    this.loadAddresses();
    this.getExportTemplate();
    this.setRecipients();

    this._searchTerm.pipe(
      tap((searchTerm) => {
        this.recipientOptions = searchTerm ? [{ label: searchTerm, value: searchTerm }] : [];
      }),
      filter((searchTerm) => !!searchTerm),
      debounceTime(500),
      switchMap((searchTerm) => {
        return this._addressDataService.searchEmailAddress(searchTerm);
      }))
      .subscribe({
        next: (response: ResultWrapper<CompanyAddressModel[]>) => {
          if (response.hasResult) {
            this.recipientOptions = this.recipientOptions.concat(
              response.result?.map((x) => {
                return {
                  label: x.value,
                  value: x.value,
                  customItemTemplate: {
                    companyName: x.company?.name || null,
                    contactName: x.contact?.nameInfo || null
                  }
                };
              })
            );
          }
        }
      }
    );
  }

  filterChanged(searchTerm?: string) {
    this._searchTerm.next(searchTerm || null);
  }

  getExportTemplate() {
    if (!this._exportOption.templateId) {
      return;
    }

    this._subscription.add(
      this.templateData
        .getById(this._exportOption.templateId)
        .subscribe((temp) => {
          if (temp.hasResult) {
            this.exportTemplate = temp.result;

            this.loadSenderAddresses();

            if (this.showPreview) {
              this.preview();
              this.previewAttachments();
            }
          }
        })
    );
  }

  dismiss() {
    // clean up all uploaded files fom this modal
    if (this.allIncludedFiles.length > 0) {
      this.allIncludedFiles.forEach((file) => {
        if (file?.allowDelete === true) {
          this.fileData.deleteFile(file.id).subscribe();
        }
      });
    }

    this.modal.dismiss();
  }

  loadAddresses() {
    if (!this.exportOption.templateId) {
      return;
    }
    this.contentDistributionData
      .getDistributionAddresses(
        this.exportOption.templateId,
        this.objectIds,
        this.objectType
      )
      .subscribe({
        next: (addressResult) => {
          if (
            addressResult &&
            addressResult.hasResult &&
            addressResult.result &&
            addressResult.result.length > 0
          ) {
            this.setRecipients(addressResult.result);
          }
        },
        error: (errorEvt) => {
          this.addressError = errorEvt;
        },
      });
  }

  loadSenderAddresses() {
    this._subscription.add(
      this._notificationConfigurationService
        .loadSenderAddresses(AddressTypes.Email)
        .subscribe((addresses: NotificationConfigurationModel) => {
          if (
            !addresses?.fromAddress ||
            !addresses?.additionalAddresses?.length
          ) {
            return;
          }

          this.senderAddresses = [
            addresses.fromAddress,
            ...addresses.additionalAddresses.map((addr) => addr.fromAddress),
          ];

          const defaultSenderAddress =
            this.exportTemplate?.templateConfiguration?.defaultSenderAddress;

          if (defaultSenderAddress) {
            this.form.controls.fromAddress.patchValue(defaultSenderAddress);
          } else {
            this.form.controls.fromAddress.patchValue(addresses.fromAddress);
          }
        })
    );
  }

  ok() {
    this.form.submit();
    if (!this.form.valid) {
      return;
    }

    const formValue = this.form.getRawValue();
    const recipients = formValue.selectedRecipients.map((recipientAdress) => {
      return (
        this.recipients.find((find) => find.address === recipientAdress) ?? {
          address: recipientAdress,
        }
      );
    });

    this.modal.close({
      body: this.notificationBodyLoaded
        ? formValue.notificationBody
        : undefined,
      recipients: recipients,
      subject: formValue.subject,
      fileIds: this.allIncludedFiles
        .filter((file) => file.allowDelete === true)
        .map((file) => file.id),
      fromAddress: formValue.fromAddress,
    });
  }

  preview() {
    this.loadingPreview = true;

    this.contentDistributionData
      .applyTemplateContent(
        this.exportTemplate.templateConfiguration.defaultExportFilename,
        this.exportTemplate.id,
        this.exportTemplate.mappingKey,
        this.objectIds,
        JSON.stringify(this.data)
      )
      .subscribe((res) => {
        this.form.controls.subject.patchValue(res.result);
      });

    this.contentDistributionData
      .generateExportBody(
        this._exportOption,
        this.objectIds,
        this.objectType,
        null,
        JSON.stringify(this.data)
      )
      .subscribe({
        next: (exportResult) => {
          if (exportResult && exportResult.length > 0) {
            this.form.controls.notificationBody.patchValue(exportResult);
            this.notificationBodyLoaded = true;
          }
          this.loadingPreview = false;
        },
        error: (errorEvt) => {
          this.previewError = errorEvt;
          this.loadingPreview = false;
        },
      });

    this.showPreview = !this.showPreview;
  }

  previewAttachments() {
    const includedTemplates$ =
      this.exportTemplate.templateConfiguration.includedExportTemplates &&
      this.exportTemplate.templateConfiguration.includedExportTemplates.length >
        0
        ? forkJoin(
            this.exportTemplate.templateConfiguration.includedExportTemplates.map(
              (inclTemp) => this.templateData.getById(inclTemp)
            )
          )
        : of([]);

    const includedFiles$ =
      this.exportTemplate.templateConfiguration.includeFileIds &&
      this.exportTemplate.templateConfiguration.includeFileIds.length > 0
        ? forkJoin(
            this.exportTemplate.templateConfiguration.includeFileIds.map(
              (inclFile) => this.fileData.getFileById(inclFile)
            )
          )
        : of([]);

    forkJoin([includedTemplates$, includedFiles$])
      .pipe(
        map(([includedTemplates, includedFiles]) => ({
          includedTemplates,
          includedFiles,
        }))
      )
      .subscribe(({ includedTemplates, includedFiles }) => {
        if (includedTemplates && includedFiles) {
          this.objectIds.forEach((id) => {
            this.includedTemplates.push(
              ...includedTemplates.map((inclTemp) => ({
                ...inclTemp.result,
                fileType: '.' + inclTemp.result.documentType.defaultExtension,
                objectId: id,
              }))
            );
          });

          const loadedFiles = includedFiles.filter((file) => file.hasResult);
          this.allIncludedFiles.push(
            ...loadedFiles.map((inclFile) => inclFile.result)
          );
        }
      });
  }

  setRecipients(recipients?: ContentDistributionRecipientModel[]) {
    if (recipients && recipients.length > 0) {
      for (let iRecipient = 0; iRecipient < recipients.length; iRecipient++) {
        const recipient = recipients[iRecipient];
        if (
          !this.recipients.find(
            (findRecipient) => findRecipient.address === recipient.address
          )
        ) {
          this.recipients.push(recipient);
        }
      }
    }
    this.form.patchValue({
      selectedRecipients: this.recipients.map((recipient) => recipient.address),
    });
  }

  fileUploaded(file: any) {
    if (file) {
      file.allowDelete = true;
      this.allIncludedFiles.push(file);
    }
  }

  fileDelete(id: any) {
    const file = this.allIncludedFiles.find(
      (file) => file.id === id && file.allowDelete === true
    );
    if (file) {
      this.fileData.deleteFile(id).subscribe(
        (res) => {
          if (
            res !== undefined &&
            res !== null &&
            res.result !== undefined &&
            res.result !== null
          ) {
            this.allIncludedFiles = this.allIncludedFiles.filter(
              (file) => file.id !== id
            );
          } else {
            this.notificationService.displayErrorNotification(res?.messages);
          }
        },
        (e) => {
          this.notificationService.displayErrorNotification(e?.error?.messages);
        }
      );
    }
  }

  ngOnDestroy(): void {
    this._searchTerm.complete();
    this._subscription.unsubscribe();
  }
}
