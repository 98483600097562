import { inject, Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as _ from 'underscore';
import { FormattingService } from '../../core/services/formatting.service';
import { LayoutService, SIDEBAR_LOCAL_STORAGE } from '@seahorse/layout';
import { NotificationService } from '@seahorse/common';
import { PreferenceDataService } from '../../preference/services/preference-data.service';
import { IdentityService } from '../../core/services/identity.service';
import { PreferenceModel } from '../../preference/models/preference.model';

@Injectable({
  providedIn: 'root',
})
export class LayoutDisplayService implements OnDestroy {
  private _currentModuleName = '<none>';
  get currentModuleName() {
    return this._currentModuleName;
  }
  set currentModuleName(value) {
    // because various components are setting this value in ngOnInit,
    // the ExpressionChangedAfterItHasBeenCheckedError is thrown. Workaround.
    setTimeout(() => {
      this._currentModuleName = value;
    });
  }

  showBaseLayout = true;
  private navigationReloadSource = new Subject();
  navigationReload$ = this.navigationReloadSource.asObservable();

  sidebarOpen: boolean;
  private _preferencesService = inject(PreferenceDataService);
  private _identityService = inject(IdentityService);
  private _notificationService = inject(NotificationService);
  private _layoutService = inject(LayoutService);
  private subscriptions$ = new Subscription();

  constructor(
    private activeRoute: ActivatedRoute,
    private formatting: FormattingService,
    private router: Router,
    private translate: TranslateService
  ) {
    this.subscriptions$.add(
      this._layoutService.sidebarToggle$.subscribe((currentValue) => {
        this.sidebarOpen = currentValue;
      })
    );

    this.initializeSidebarOpen();
    window.addEventListener('storage', this.handleStorageChange.bind(this));

    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe((e) => {
        this.handleRouterEvent(e);
      });

    // First time check
    this.handleRouterEvent(this.activeRoute);
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
    window.removeEventListener('storage', this.handleStorageChange.bind(this));
  }

  private handleRouterEvent(event) {
    this.extractModuleNameFromRoute(this.activeRoute);

    const showBase = this.getDataElementFromRoute(
      this.activeRoute,
      'ui.showBaseLayout',
      true
    );
    if (showBase !== null) {
      this.showBaseLayout = showBase;
    } else {
      this.showBaseLayout = true;
    }
  }

  private extractModuleNameFromRoute(route: ActivatedRoute) {
    const moduleName = this.getDataElementFromRoute(
      route,
      'ui.headerTitle',
      true
    );

    if (moduleName && moduleName !== '') {
      this.currentModuleName = moduleName;
      document.title = 'SHIPM8 - ' + this.translate.instant(moduleName);
    }
  }

  private getDataElementFromRoute(
    route: ActivatedRoute,
    itemPath: string,
    iterateChildren = false
  ) {
    let result: any = null;
    if (route.snapshot && route.snapshot.data) {
      const propValue = this.formatting.getPropertyByPath(
        route.snapshot.data,
        itemPath
      );
      if (propValue !== undefined && propValue !== null) {
        result = propValue;
      }
    }

    if (
      route.children &&
      route.children.length > 0 &&
      iterateChildren === true
    ) {
      _.each(route.children, (child) => {
        const childValue = this.getDataElementFromRoute(child, itemPath, true);
        if (childValue !== undefined && childValue !== null) {
          result = childValue;
        }
      });
    }

    return result;
  }

  reloadNavigation() {
    this.navigationReloadSource.next();
  }

  toggleSidebarOpen() {
    const newValue = !this.sidebarOpen;
    localStorage.setItem(SIDEBAR_LOCAL_STORAGE.isOpen, newValue.toString());

    const event = new StorageEvent('storage', {
      key: SIDEBAR_LOCAL_STORAGE.isOpen,
      newValue: newValue.toString(),
      oldValue: (!newValue).toString(),
      storageArea: localStorage,
      url: window.location.href,
    });
    this.handleStorageChange(event);

    const sidebarOpenPreference = {
      name: 'sidebarOpen',
      fieldType: 3,
      fieldValue: newValue,
      category: 'layout',
    } as PreferenceModel;

    this._preferencesService.save(sidebarOpenPreference).subscribe({
      next: (response) => {
        if (response.hasResult) {
          this._identityService.setPreferences([response.result]);
        } else {
          this._notificationService.displayErrorNotification(response.messages);
        }
      },
      error: (e) => this._notificationService.displayErrorNotification(e),
    });
  }

  private initializeSidebarOpen() {
    const sidebarOpenLocalStorage = localStorage.getItem(
      SIDEBAR_LOCAL_STORAGE.isOpen
    );
    let initialSidebarOpen: boolean;

    if (
      sidebarOpenLocalStorage === null ||
      sidebarOpenLocalStorage === undefined
    ) {
      const sidebarOpenPreference =
        this._identityService
          .getPreferences()
          ?.find((x) => x.name === 'sidebarOpen' && x.category === 'layout')
          ?.fieldValue?.toString()
          .toLowerCase() === 'true';

      initialSidebarOpen = sidebarOpenPreference ?? true;
      localStorage.setItem(
        SIDEBAR_LOCAL_STORAGE.isOpen,
        initialSidebarOpen.toString()
      );
    } else {
      initialSidebarOpen = sidebarOpenLocalStorage === 'true';
    }

    this._layoutService.toggleSidebar(initialSidebarOpen);
  }

  private handleStorageChange(event: StorageEvent) {
    if (event.key === SIDEBAR_LOCAL_STORAGE.isOpen) {
      this._layoutService.toggleSidebar(event.newValue === 'true');
    }
  }
}
