<sh-control *ngIf="control" [control]="this">
  <ejs-multiselect
    [addTagOnBlur]="true"
    [allowCustomValue]="!mustMatchOptions"
    [attr.name]="formControlName"
    [dataSource]="options"
    [fields]="fieldMapping"
    [formControl]="control"
    [id]="formControlName"
    [placeholder]="placeholder"
    [maximumSelectionLength]="maxItems"
    (select)="onItemSelect($event)"
    (removed)="onItemDeselect($event)"
    (valueChange)="emitOnChange($event)"
    (filtering)="onFilterChange($event)"
    [allowFiltering]="true"
    mode="Box"
  >
    <ng-template *ngIf="customItemTemplate" #itemTemplate let-data>
      <ng-container *ngTemplateOutlet="customItemTemplate; context: { $implicit: data }"></ng-container>
    </ng-template>
  </ejs-multiselect>
</sh-control>
