import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { CompanyFinancialModel } from '@seahorse/domain';
import { ContentDistributionDataService } from '../../../../content-distribution/services/content-distribution-data.service';
import { IdentityService } from '../../../../core/services/identity.service';
import * as _ from 'underscore';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ca-financial-form',
  templateUrl: './financial-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinancialFormComponent implements OnInit, OnDestroy {
  @Input() model: CompanyFinancialModel;
  @Input() submitted = false;

  onlyReadPermission: boolean;
  templates: any[] = [];

  isLoading = false;

  private _subscription = new Subscription();

  private _cdr = inject(ChangeDetectorRef);
  private _contentDistributionService = inject(ContentDistributionDataService);
  private _identity = inject(IdentityService);

  ngOnInit() {
    this.onlyReadPermission = false;

    this.loadTemplates();

    const writePermission: boolean = this._identity.hasPermission(
      'companies.company_financials_update'
    );
    const readPermission: boolean = this._identity.hasPermission(
      'companies.company_financials'
    );

    if (!writePermission && readPermission) {
      this.onlyReadPermission = true;
    }
  }

  loadTemplates() {
    const preference = _.find(this._identity.getPreferences(), (p) => {
      return 'language' === p.name.toLowerCase();
    });

    const language =
      preference && preference.fieldValue ? preference.fieldValue : null;

    this.isLoading = true;

    this._subscription.add(
      this._contentDistributionService
        .getExportOptions(language)
        .subscribe((exportOptionResult) => {
          if (exportOptionResult.hasResult) {
            this.templates = [];

            const exportOption = _.find(
              exportOptionResult.result,
              (f) => f.mappingKey === '$invoicing_invoice'
            );
            if (exportOption && exportOption.options) {
              this.templates = this.templates.concat(
                _.map(
                  _.filter(
                    exportOption.options,
                    (f) => f.documentType.distributionService !== null
                  ),
                  (opt) => ({ id: opt.templateId, name: opt.templateName })
                )
              );
            }
          }

          this.isLoading = false;
          this._cdr.detectChanges();
        })
    );
  }

  onEndDateChanged(d) {
    this.model.endsOn = d.dateString;
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
