import { Injectable } from '@angular/core';
import { DataContextService } from '@seahorse/common';
import { Observable } from 'rxjs';

import { CompanyAddressModel, DataService } from '@seahorse/domain';
import { ResultWrapper } from '@seahorse/common';

@Injectable()
export class AddressDataService implements DataService<CompanyAddressModel> {
  constructor(private dataContext: DataContextService) {}

  getById(addressId: number): Observable<ResultWrapper<CompanyAddressModel>> {
    return this.dataContext.get<CompanyAddressModel>(`addresses/${addressId}`);
  }

  add(
    address: CompanyAddressModel
  ): Observable<ResultWrapper<CompanyAddressModel>> {
    return this.dataContext.post<CompanyAddressModel>(`addresses`, address);
  }

  update(
    address: CompanyAddressModel
  ): Observable<ResultWrapper<CompanyAddressModel>> {
    return this.dataContext.put<CompanyAddressModel>(
      `addresses/${address.id}`,
      address
    );
  }

  delete(addressId: number): Observable<ResultWrapper<CompanyAddressModel>> {
    return this.dataContext.delete<CompanyAddressModel>(
      `addresses/${addressId}`
    );
  }

  searchEmailAddress(searchQuery?: string): Observable<ResultWrapper<CompanyAddressModel[]>> {
    const queryParams = [];
    if (searchQuery) {
      queryParams.push(`searchTerm=${encodeURIComponent(searchQuery)}`);
    }

    let url = 'addresses/email-address';
    if (queryParams.length) {
      url += `?${queryParams.join('&')}`;
    }

    return this.dataContext.get<CompanyAddressModel[]>(url);
  }
}
