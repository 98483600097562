import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PERMISSIONGROUPS } from '../../../../core/models/permission.model';
import { ResultStatus } from '@seahorse/common';
import { IdentityService } from '../../../../core/services/identity.service';
import { NotificationService } from '@seahorse/common';
import { LayoutDisplayService } from '../../../../layout/services/layout-display.service';
import { UserDataService } from '../../../../user/services/user-data.service';
import { OrganisationModel } from '../../../../content-distribution/models/organisation.model';
import { SeahorseAccountService } from '../../services/user-account.service';
import { Guid } from 'guid-typescript';
import { CreateEnvironmentComponent } from '../create-environment/create-environment.component';

@Component({
  selector: 'ca-set-permissions',
  templateUrl: './set-permissions.component.html',
})
export class SetPermissionsComponent implements OnInit {
  @ViewChild(CreateEnvironmentComponent)
  createEnvironmentComponent: CreateEnvironmentComponent;

  permissions = PERMISSIONGROUPS;
  isLoading = false;
  hasStartedCreatingEnvironment = false;
  somethingWentWrong = false;
  seedOrganizations: OrganisationModel[] = [];
  selectedOrganisationId: Guid;

  constructor(
    private layoutDisplay: LayoutDisplayService,
    private userDataService: UserDataService,
    private notificationService: NotificationService,
    private router: Router,
    private translateService: TranslateService,
    private identityService: IdentityService,
    private userAccountService: SeahorseAccountService
  ) {}

  ngOnInit() {
    this.layoutDisplay.currentModuleName = this.translateService.instant(
      'account.register.permissions.title'
    );

    this.userAccountService
      .getDemoOrganisations()
      .subscribe((organisations) => {
        this.seedOrganizations = organisations.result;
      });
  }

  save() {
    this.isLoading = true;
    this.hasStartedCreatingEnvironment = true;
    this.somethingWentWrong = false;

    this.createEnvironmentComponent.createEnvironment().subscribe(
      (environmentCreated) => {
        if (!environmentCreated) {
          this.isLoading = false;
          this.somethingWentWrong = true;
          return;
        }

        const permissionNames = this.permissions
          .filter((x) => x.checked)
          .map((x) => x.name);

        this.userDataService.setPermissions(permissionNames).subscribe(
          (result) => {
            if (result.status === ResultStatus.OK) {
              this.notificationService.showSuccess(
                'Permissions added!',
                'Successfully'
              );

              this.identityService.identity = result.result.user;
              this.identityService.setPermissions(result.result.permissions);

              this.router.navigate(['/dashboard']);
              window.location.reload();
            } else {
              this.notificationService.showError(
                'Something went wrong!',
                'Failed'
              );
              this.isLoading = false;
              this.somethingWentWrong = true;
            }
          },
          () => {
            this.isLoading = false;
            this.somethingWentWrong = true;
          }
        );
      },
      () => {
        this.isLoading = false;
        this.somethingWentWrong = true;
      }
    );
  }
}
