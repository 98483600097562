<sh-language-picker
  class="d-flex justify-content-end m-2"
></sh-language-picker>

<div class="login-wrapper">
  <div class="login-form">
    <section>
      <div class="login-logo">{{ 'shared.branding.shipm8' | translate }}</div>
      <p>{{ 'account.resetPassword.title' | translate }}</p>
      <form
        #resetPasswordForm="ngForm"
        (ngSubmit)="resetPassword(resetPasswordForm)"
      >
        <div class="form-group">
          <label class="control-label" for="newPassword">{{
            'account.resetPassword.newPassword' | translate
          }}</label>
          <input
            #newPassword="ngModel"
            [(ngModel)]="resetPasswordForm.newPassword"
            [disabled]="isLoading"
            [placeholder]="
              'account.resetPassword.placeholder.newPassword' | translate
            "
            required
            id="newPassword"
            name="newPassword"
            type="password"
            class="form-control"
          />
        </div>
        <div class="form-group">
          <label class="control-label" for="confirmPassword">{{
            'account.resetPassword.confirmPassword' | translate
          }}</label>
          <input
            #confirmPassword="ngModel"
            [(ngModel)]="resetPasswordForm.confirmPassword"
            [disabled]="isLoading"
            [placeholder]="
              'account.resetPassword.placeholder.confirmPassword' | translate
            "
            required
            id="confirmPassword"
            name="confirmPassword"
            type="password"
            class="form-control"
          />
        </div>
        <ul *ngIf="errorMessages.length" class="alert alert-danger">
          <li *ngFor="let errorMessage of errorMessages">
            {{ errorMessage }}
          </li>
        </ul>
        <button [disabled]="isLoading" type="submit" class="btn btn-primary">
          <span *ngIf="isLoading">
            <i class="fa-regular fa-spinner fa-spin fa-fw"></i>
          </span>
          {{ 'account.resetPassword.title' | translate }}
        </button>
      </form>
    </section>
  </div>
</div>
