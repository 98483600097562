import { Component, OnDestroy, inject, Input } from '@angular/core';
import { ErrorMessage, SIGNALR_ACTION, SignalRService } from '@seahorse/common';
import { EMPTY, Observable, of, Subject, Subscription, throwError } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { CreateEnvironmentModel } from '../../../models/create-environment.model';
import { SeahorseAccountService } from '../../services/user-account.service';
import { OrganisationModel } from '../../../../content-distribution/models/organisation.model';
import {
  CreateEnvironmentMessage,
  createEnvironmentMessageFromString,
  CreateEnvironmentProgressMessage,
  MessageCode,
  MessageType,
} from '../../../models/create-environment-message.model';
import { FieldStatusModel } from '@seahorse/domain';

@Component({
  selector: 'ca-create-environment',
  templateUrl: './create-environment.component.html',
  styleUrls: ['./create-environment.component.scss'],
})
export class CreateEnvironmentComponent implements OnDestroy {
  @Input() organisationId: OrganisationModel['id'];

  messages: CreateEnvironmentMessage[] = [];
  progress: FieldStatusModel = { Progress: 0, Total: 0 };
  isLoading = false;

  private _signalRService = inject(SignalRService);
  private _userAccountService = inject(SeahorseAccountService);
  private _destroy = new Subject<void>();
  private _subscriptions = new Subscription();

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
    this._subscriptions.unsubscribe();
  }

  createEnvironment(): Observable<boolean> {
    if (this.isLoading) return of(false);
    this.isLoading = true;

    return this._signalRService.createConnection().pipe(
      mergeMap((connection) => {
        const model = {
          sourceOrganisationId: this.organisationId,
          signalR_Id: connection.connectionId,
        } as CreateEnvironmentModel;

        return this._userAccountService.createEnvironment(model).pipe(
          catchError((e) => {
            this.createEnvironmentSystemError(e);
            return of(e);
          })
        );
      }),
      mergeMap((response) => {
        if (!response.hasResult) {
          this.isLoading = false;
          this.createEnvironmentSystemError(response.messages);
          return EMPTY;
        }

        return this._signalRService.receiveMessage;
      }),
      takeUntil(this._destroy),
      tap((message) => {
        if (!message) return;

        const createEnvironmentMessage = createEnvironmentMessageFromString(
          message.message
        );

        if (createEnvironmentMessage['messageType']) {
          this.messages.unshift(
            createEnvironmentMessage as CreateEnvironmentMessage
          );
        } else {
          this.progress = {
            Progress: (
              createEnvironmentMessage as CreateEnvironmentProgressMessage
            ).currentStep,
            Total: (
              createEnvironmentMessage as CreateEnvironmentProgressMessage
            ).totalSteps,
          };
        }
      }),
      catchError((e) => {
        this.isLoading = false;
        return throwError(e);
      }),
      filter((message) => {
        if (!message) return;

        const createEnvironmentMessage = createEnvironmentMessageFromString(
          message.message
        );

        if (
          message.action === SIGNALR_ACTION.DISCONNECT ||
          createEnvironmentMessage.messageCode ===
            MessageCode.SCRIPT_EXECUTION_EXITED
        ) {
          throwError(message.message);
          return false;
        }

        if (
          createEnvironmentMessage.messageCode ===
          MessageCode.SCRIPT_EXECUTION_COMPLETED
        ) {
          this.isLoading = false;
        }

        if (
          createEnvironmentMessage.messageCode ===
          MessageCode.SCRIPT_EXECUTION_SUCESSFUL
        ) {
          return true;
        }
      }),
      map(() => true)
    );
  }

  private createEnvironmentSystemError(systemError?: string | ErrorMessage[]) {
    const createEnvironmentMessage = {
      timestamp: new Date(),
      messageType: MessageType.ERROR,
      messageCode: MessageCode.SYSTEM_ERROR,
      data: systemError
        ? typeof systemError === 'string'
          ? systemError
          : systemError.map((x) => `${x.code} - ${x.message}`).join(', ')
        : null,
    } as CreateEnvironmentMessage;

    this.messages.unshift(createEnvironmentMessage);
  }
}
