import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UpdatePasswordModel } from '@seahorse/auth';
import { NotificationService } from '@seahorse/common';
import * as _ from 'underscore';
import { SeahorseAccountService } from '../../services/user-account.service';

@Component({
  selector: 'ca-reset-password',
  templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent {
  errorMessages!: string[];
  isLoading!: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private userAccount: SeahorseAccountService,
    private notification: NotificationService
  ) {
    this.errorMessages = [];
    this.isLoading = false;
  }

  resetPassword(ngForm: NgForm) {
    this.errorMessages = [];

    if (!ngForm['newPassword']) {
      this.errorMessages.push(
        this.translate.instant(
          'account.resetPassword.error.newPasswordRequired'
        )
      );
    }

    if (!ngForm['confirmPassword']) {
      this.errorMessages.push(
        this.translate.instant(
          'account.resetPassword.error.confirmPasswordRequired'
        )
      );
    }

    if (
      ngForm['newPassword'] &&
      ngForm['newPassword'] !== ngForm['confirmPassword']
    ) {
      this.errorMessages.push(
        this.translate.instant(
          'account.resetPassword.error.passwordsNotMatching'
        )
      );
    }

    if (ngForm.form.status === 'INVALID' || this.errorMessages.length) {
      return;
    }

    this.route.queryParams.subscribe((params) => {
      const updatePasswordModel = new UpdatePasswordModel(
        params['key'],
        ngForm['newPassword']
      );

      this.userAccount.updatePassword(updatePasswordModel).subscribe(
        (r) => {
          if (r) {
            this.notification.showSuccess(
              this.translate.instant('account.resetPassword.passwordUpdated'),
              this.translate.instant('shared.terms.success')
            );

            this.router.navigate(['/account/login']);
          } else {
            this.notification.showError(
              this.translate.instant('shared.terms.failed')
            );
          }
        },

        (e) => {
          this.notification.showError(
            _.pluck(e.error.messages, 'message').join('\n'),
            this.translate.instant('shared.terms.failed')
          );
        },

        () => (this.isLoading = false)
      );
    });
  }
}
